import {Injectable} from '@angular/core';
import {DataServiceInputClient, AgentClient} from '@bfs-sis/bfs-iop-admin-web-api-client';
import {FallbackPipe} from 'src/app/shared/fallback/fallback.pipe';
import {DataserviceStatusService} from './dataservice-status.service';

@Injectable()
export class DataserviceInjectablesService {
	constructor(
		private readonly _dataServiceInputClient: DataServiceInputClient,
		private readonly _dataserviceStatusService: DataserviceStatusService,
		private readonly _fallbackPipe: FallbackPipe,
		private readonly _agentClient: AgentClient
	) {}

	get dataServiceInputClient(): DataServiceInputClient {
		return this._dataServiceInputClient;
	}

	get dataserviceStatusService(): DataserviceStatusService {
		return this._dataserviceStatusService;
	}

	get fallbackPipe(): FallbackPipe {
		return this._fallbackPipe;
	}

	get agentClient(): AgentClient {
		return this._agentClient;
	}
}
