<table mat-table matSort id="catalog-table" [dataSource]="dataSource" (matSortChange)="sortData($event)">
	<ng-container [matColumnDef]="COLUMN_TITLE">
		<th mat-header-cell mat-sort-header *matHeaderCellDef>
			{{ "i18n.page.catalog.table.column.dataset.title" | translate }}
		</th>
		<td mat-cell *matCellDef="let element">
			<div [routerLink]="getDetailUrl(element)" class="cursor-pointer">
				{{ element.title | fallback: currentLanguage }}
			</div>
		</td>
	</ng-container>

	<ng-container [matColumnDef]="COLUMN_IDENTIFIERS">
		<th mat-header-cell mat-sort-header *matHeaderCellDef>
			{{ "i18n.page.catalog.table.column.identifier.title" | translate }}
		</th>
		<td mat-cell *matCellDef="let element">
			<div [routerLink]="['datasets', element.id]" *ngIf="isDataSet(element)" class="cursor-pointer">
				{{ element.identifiers | arraytostring }}
			</div>
			<div *ngIf="!isDataSet(element)">
				{{ element.identifiers | arraytostring }}
			</div>
		</td>
	</ng-container>

	<ng-container [matColumnDef]="COLUMN_PUBLISHERNAME">
		<th mat-header-cell mat-sort-header *matHeaderCellDef>
			{{ "i18n.page.catalog.table.column.publisher.title" | translate }}
		</th>
		<td mat-cell *matCellDef="let element">
			{{ element.publisherName | fallback: currentLanguage }}
		</td>
	</ng-container>

	<ng-container [matColumnDef]="COLUMN_TYPE">
		<th mat-header-cell mat-sort-header *matHeaderCellDef>
			{{ "i18n.page.catalog.table.column.type.title" | translate }}
		</th>
		<td mat-cell *matCellDef="let element">
			{{ element.type }}
		</td>
	</ng-container>

	<ng-container [matColumnDef]="COLUMN_STATUS">
		<th mat-header-cell mat-sort-header *matHeaderCellDef>
			{{ "i18n.page.catalog.table.column.registrationstatus.title" | translate }}
		</th>
		<td mat-cell *matCellDef="let element">
			<div
				[ngClass]="element.registrationStatus === 'Incomplete' ? 'status-chip status-chip-warning' : 'status-chip status-chip-success'"
				*ngIf="element.registrationStatus"
			>
				<mat-chip-list>
					<mat-chip
						><div>
							{{ "i18n.shared.status.registrationstatus." + element.registrationStatus.replace(" ", "").toLowerCase() | translate }}
						</div>
						<mat-icon
							svgIcon="clock"
							[ngClass]="{ 'status-chip-warning': element.registrationStatus && element.registrationStatus === 'Incomplete' }"
							*ngIf="element.registrationStatusProposal"
							[matTooltip]="
								('i18n.shared.proposal.status' | translate) +
								('i18n.shared.status.registrationstatus.' + element.registrationStatusProposal.replace(' ', '').toLowerCase() | translate)
							"
						></mat-icon
					></mat-chip>
				</mat-chip-list>
			</div>
		</td>
	</ng-container>

	<ng-container [matColumnDef]="COLUMN_PUBLICATION">
		<th mat-header-cell mat-sort-header *matHeaderCellDef>
			{{ "i18n.page.catalog.table.column.publicationlevel.title" | translate }}
		</th>
		<td mat-cell *matCellDef="let element">
			<div class="status-chip status-chip-info" *ngIf="element.publicationLevel">
				<mat-chip-list>
					<mat-chip
						><div>
							{{ "i18n.shared.status.publicationlevel." + element.publicationLevel.replace(" ", "").toLowerCase() | translate }}
						</div>
						<mat-icon
							svgIcon="clock"
							*ngIf="element.publicationLevelProposal"
							[matTooltip]="
								('i18n.shared.proposal.publication' | translate) +
								('i18n.shared.status.publicationlevel.' + element.publicationLevelProposal.replace(' ', '').toLowerCase() | translate)
							"
						></mat-icon
					></mat-chip>
				</mat-chip-list>
			</div>
		</td>
	</ng-container>

	<ng-container [matColumnDef]="COLUMN_ACTIONS">
		<th mat-header-cell mat-sort-header *matHeaderCellDef></th>
		<td mat-cell *matCellDef="let element; let i = index">
			<a
				id="view-detail-button-{{ i }}"
				obButton="secondary"
				mat-icon-button
				type="button"
				matTooltip="{{ 'i18n.page.catalog.table.column.buttons.viewdetail.tooltip' | translate }}"
				[routerLink]="getDetailUrl(element)"
			>
				<mat-icon svgIcon="eye" class="ob-icon"></mat-icon>
			</a>
		</td>
	</ng-container>

	<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
	<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<div class="table-info-box">
	<app-infobox-no-content [datasource]="dataSource.data"></app-infobox-no-content>
</div>
