import {MultiLanguage} from '@bfs-sis/bfs-iop-admin-web-api-client';

export class MultiLanguageMapper {
	public static mapElements(elements: MultiLanguage[]): MultiLanguage[] {
		return elements.map(x => MultiLanguageMapper.clone(x));
	}

	/**
	 * Clone a MultiLanguage instance and set empty values to undefined
	 * @param element The instance to clone
	 * @returns A cloned instance with undefined properties instead of epmpty strings
	 */
	public static clone(element: MultiLanguage | undefined): MultiLanguage {
		return new MultiLanguage({
			de: element?.de || undefined,
			fr: element?.fr || undefined,
			it: element?.it || undefined,
			en: element?.en || undefined,
			rm: element?.rm || undefined
		});
	}

	/**
	 * Set empty string properties to undefined
	 * @param element The instance to fix
	 */
	public static fixEmptyValues(element: MultiLanguage | undefined): MultiLanguage | undefined {
		if (element) {
			element.de ||= undefined;
			element.fr ||= undefined;
			element.it ||= undefined;
			element.en ||= undefined;
			element.rm ||= undefined;
		}
		return element;
	}
}
