<div class="detail-table-row-wrapper">
	<table mat-table matSort matSortDisableClear [dataSource]="dataSource">
		<ng-container [matColumnDef]="COLUMN_SELECT">
			<th mat-header-cell *matHeaderCellDef>
				<mat-checkbox
					(change)="$event ? onMasterToggle() : null"
					[checked]="hasSelectedRows() && isAllSelected()"
					[indeterminate]="hasSelectedRows() && !isAllSelected()"
					[disabled]="isEditMode()"
					matTooltip="{{ 'i18n.app.edittable.checkbox.all.tooltip' | translate }}"
				>
				</mat-checkbox>
			</th>
			<td mat-cell *matCellDef="let row">
				<mat-checkbox
					(click)="$event.stopPropagation()"
					(change)="$event ? onToggleSelection(row) : null"
					[checked]="isRowSelected(row)"
					[disabled]="isEditMode()"
					matTooltip="{{ 'i18n.app.edittable.checkbox.single.tooltip' | translate }}"
				>
				</mat-checkbox>
			</td>
		</ng-container>

		<ng-container [matColumnDef]="COLUMN_NAME">
			<th mat-header-cell *matHeaderCellDef>{{ "i18n.shared.channel.link.name" | translate }}</th>
			<td mat-cell *matCellDef="let element; let i = index">
				<mat-form-field [hidden]="!isRowEditMode(i)">
					<input type="text" matInput placeholder="Select State" aria-label="State" [matAutocomplete]="auto" [formControl]="publicOrganisationFormControl" />
					<mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectionChanged($event)" [displayWith]="displayValue">
						<mat-option *ngFor="let po of filteredPublicOrganisations$ | async" [value]="po">
							<span>{{ po.name | fallback: currentLanguage }}</span>
						</mat-option>
					</mat-autocomplete>
				</mat-form-field>
				<span>{{ element.name | fallback: currentLanguage }}</span>
			</td>
		</ng-container>

		<ng-container [matColumnDef]="COLUMN_ACTIONS">
			<th mat-header-cell mad-sort-header *matHeaderCellDef>
				<button
					obButton="secondary"
					mat-icon-button
					matTooltip="{{ 'i18n.app.edittable.button.deleteSelected.tooltip' | translate }}"
					(click)="onRemoveSelectedRows()"
					[disabled]="hasSelectedRows() === false"
				>
					<mat-icon svgIcon="unlink" class="ob-icon"></mat-icon>
				</button>
				<button
					obButton="secondary"
					mat-icon-button
					(click)="onAddRow()"
					[disabled]="!canAdd() || !editMode"
					matTooltip="{{ 'i18n.app.edittable.button.addrow.tooltip' | translate }}"
				>
					<mat-icon svgIcon="plus" class="ob-icon"></mat-icon>
				</button>
			</th>
			<td mat-cell *matCellDef="let row; let i = index">
				<button
					*ngIf="isRowEditMode(i)"
					obButton="secondary"
					mat-icon-button
					(click)="onDiscard()"
					[disabled]="hasSelectedRows()"
					matTooltip="{{ 'i18n.app.edittable.button.discard.tooltip' | translate }}"
				>
					<mat-icon svgIcon="cancel" class="ob-icon"></mat-icon>
				</button>

				<button
					*ngIf="isRowEditMode(i)"
					obButton="secondary"
					mat-icon-button
					(click)="onSave()"
					[disabled]="!canSave(i)"
					matTooltip="{{ 'i18n.app.edittable.button.save.tooltip' | translate }}"
				>
					<mat-icon svgIcon="checkmark" class="ob-icon"></mat-icon>
				</button>

				<button
					*ngIf="canRemove()"
					obButton="secondary"
					mat-icon-button
					(click)="onRemoveRow(i, row)"
					[disabled]="hasSelectedRows()"
					matTooltip="{{ 'i18n.page.datasets.tabs.content.unlinkbutton.tooltip' | translate }}"
				>
					<mat-icon svgIcon="unlink" class="ob-icon"></mat-icon>
				</button>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
	</table>

	<div class="table-info-box">
		<ob-alert type="info" *ngIf="!editMode">{{ "i18n.page.publicservices.channel.create.tableinfo.createmode" | translate }}</ob-alert>
		<app-infobox-no-content [datasource]="dataSource.data" *ngIf="channelInputId"></app-infobox-no-content>
	</div>
</div>
