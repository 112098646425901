<div>
	<table mat-table matSort [dataSource]="dataSource" aria-label="table">
		<ng-container [matColumnDef]="COLUMN_TYPE">
			<th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "i18n.shared.annotation.type.title" | translate }}</th>
			<td mat-cell *matCellDef="let element">
				<div class="cell">
					{{ element.type }}
				</div>
			</td>
		</ng-container>
		<ng-container [matColumnDef]="COLUMN_TITLE">
			<th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "i18n.shared.annotation.title.title" | translate }}</th>
			<td class="cell" mat-cell *matCellDef="let element">
				{{ element.title }}
			</td>
		</ng-container>
		<ng-container [matColumnDef]="COLUMN_TEXT">
			<th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "i18n.shared.annotation.text.title" | translate }}</th>
			<td mat-cell *matCellDef="let element">
				<app-multiline-text [text]="(element.text | fallback: currentLanguage) ?? '-'"></app-multiline-text>
			</td>
		</ng-container>
		<ng-container [matColumnDef]="COLUMN_URI">
			<th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "i18n.shared.annotation.uri.title" | translate }}</th>
			<td mat-cell *matCellDef="let element">
				{{ element.uri }}
			</td>
		</ng-container>
		<ng-container [matColumnDef]="COLUMN_IDENTIFIER">
			<th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "i18n.shared.annotation.identifier.title" | translate }}</th>
			<td class="cell" mat-cell *matCellDef="let element">
				{{ element.identifier }}
			</td>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
	</table>

	<div class="table-info-box">
		<app-infobox-no-content [datasource]="dataSource.data"></app-infobox-no-content>
	</div>
</div>
