<div class="filter-wrapper">
	<div class="ob-grid filter-selection" [hidden]="hideFilters">
		<div *ngFor="let section of orderedKeys" class="ob-span-3">
			<filter-multiselect-dropdown
				[selectedOptionsFromFilter]="selectedValues[section]"
				[translationDropdownFieldName]="'i18n.shared.filters.' + section.toLowerCase()"
				[dropdownOptions]="filters[section]"
				[section]="section"
				[displaySelectAllOption]="true"
				(changeEvent)="selectionChange(section, $event)"
			></filter-multiselect-dropdown>
		</div>
	</div>

	<div class="filter-chips">
		<mat-chip-list>
			<ng-container *ngFor="let section of orderedKeys">
				<mat-chip *ngFor="let selected of selectedValues[section]" (removed)="onChipRemove(section, selected)" [removable]="true">
					{{ getFilterSection(section) }}
					{{ getFilterLabel(section, selected) }}
					<mat-icon [svgIcon]="'cancel-circle'" matChipRemove></mat-icon>
				</mat-chip>
			</ng-container>
			<ng-container *ngIf="hasSelectedFilters()">
				<button mat-button [obButton]="'tertiary'" (click)="resetAllFilter()">
					<mat-icon [svgIcon]="'undo'"></mat-icon>
					{{ "i18n.shared.filters.removeall" | translate }}
				</button>
			</ng-container>
		</mat-chip-list>
	</div>
</div>
