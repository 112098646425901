import {ArrayToStringPipe} from './formatting/array-to-string.pipe';
import {CodevalueTableComponent} from './codevalue-table/codevalue-table.component';
import {CommonModule} from '@angular/common';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {DetailTableRowComponent} from './detail-table-row/detail-table-row.component';
import {DetailTableRowWithButtonComponent} from './detail-table-row-with-button/detail-table-row-with-button.component';
import {DialogComponent} from './dialog/dialog.component';
import {DisplayResourcesComponent} from './display-resources/display-resources.component';
import {EditTableCodelistComponent} from './edit-table-codelist/edit-table-codelist.component';
import {EditTableDataserviceLinkComponent} from './edit-table-dataservice-link/edit-table-dataservice-link.component';
import {EditTableMultiLanguageComponent} from './edit-table-multi-language/edit-table-multi-language.component';
import {EditTableResourceComponent} from './edit-table-resource/edit-table-resource.component';
import {FallbackPipe} from './fallback/fallback.pipe';
import {FallbackArrayToStringPipe} from './fallback/fallback-array-to-string.pipe';
import {FileSizePipe} from './formatting/file-size.pipe';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IsInvalidAndUntouchedPipe} from './validators/is-invalid-and-untouched.pipe';
import {
	MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS,
	MatLegacyFormFieldModule as MatFormFieldModule
} from '@angular/material/legacy-form-field';
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from '@angular/material/legacy-autocomplete';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatLegacyChipsModule as MatChipsModule} from '@angular/material/legacy-chips';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatIconModule} from '@angular/material/icon';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {MatMomentDateModule, MomentDateAdapter} from '@angular/material-moment-adapter';
import {MatLegacyPaginatorModule as MatPaginatorModule} from '@angular/material/legacy-paginator';
import {MatLegacyRadioModule as MatRadioModule} from '@angular/material/legacy-radio';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatSortModule} from '@angular/material/sort';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {ModalDialogComponent} from './modal-dialog/modal-dialog.component';
import {MultilineTextComponent} from './multiline-text/multiline-text.component';
import {NgModule} from '@angular/core';
import {
	ObAlertModule,
	ObButtonModule,
	ObCollapseModule,
	ObColumnLayoutModule,
	ObExternalLinkModule,
	ObMasterLayoutModule,
	ObPopoverModule,
	ObStickyModule
} from '@oblique/oblique';
import {PersonSearchPipe} from './person-search/person-search.pipe';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {InfoboxNoContentComponent} from './infobox-no-content/infobox-no-content.component';
import {EditTableDatasetLinkComponent} from './edit-table-dataset-link/edit-table-dataset-link.component';
import {TableAnnotationComponent} from './table-annotation/table-annotation.component';
import {EditTableAnnotationComponent} from './edit-table-annotation/edit-table-annotation.component';
import {ModalDialogAnnotationComponent} from './edit-table-annotation/modal-dialog/modal-dialog.component';
import {StatusComponent} from './status/status.component';
import {EditTableChannelLinkComponent} from './edit-table-channel-link/edit-table-channel-link.component';
import {EditTableQualifiedAttributionComponent} from './edit-table-qualified-attribution/edit-table-qualified-attribution.component';
import {EditTableQualifiedRelationComponent} from './edit-table-qualified-relation/edit-table-qualified-relation.component';
import {DisplayQualifiedAttributionComponent} from './display-qualified-attribution/display-qualified-attribution.component';
import {DisplayQualifiedRelationComponent} from './display-qualified-relation/display-qualified-relation.component';
import {DisplayMultiLanguageComponent} from './display-keywords/display-multi-language.component';
import {ConceptRelationTableComponent} from './concept-relation-table/concept-relation-table.component';
import {SearchFiltersComponent} from './search-filters/search-filters.component';
import {FilterMultiSelectDropdownComponent} from './search-filters/filter-multiselect-dropdown/filter-multiselect-dropdown.component';
import {ModalDialogCodeListComponent} from './edit-table-codelist/modal-dialog/modal-dialog.component';

const MODULES = [
	CommonModule,
	MatAutocompleteModule,
	MatButtonModule,
	MatCardModule,
	MatCheckboxModule,
	MatChipsModule,
	MatDatepickerModule,
	MatDialogModule,
	MatExpansionModule,
	MatFormFieldModule,
	MatIconModule,
	MatInputModule,
	MatMenuModule,
	MatMomentDateModule,
	MatNativeDateModule,
	MatPaginatorModule,
	MatRadioModule,
	MatSelectModule,
	MatSortModule,
	MatTableModule,
	MatTabsModule,
	MatTooltipModule,
	FormsModule,
	ObAlertModule,
	ObButtonModule,
	ObCollapseModule,
	ObColumnLayoutModule,
	ObExternalLinkModule,
	ObMasterLayoutModule,
	ObPopoverModule,
	ObStickyModule,
	ReactiveFormsModule,
	TranslateModule
];

const COMPONENTS = [
	CodevalueTableComponent,
	ConceptRelationTableComponent,
	DetailTableRowComponent,
	DetailTableRowWithButtonComponent,
	DialogComponent,
	DisplayMultiLanguageComponent,
	DisplayResourcesComponent,
	DisplayQualifiedAttributionComponent,
	DisplayQualifiedAttributionComponent,
	DisplayQualifiedRelationComponent,
	EditTableCodelistComponent,
	EditTableDatasetLinkComponent,
	EditTableMultiLanguageComponent,
	EditTableResourceComponent,
	FilterMultiSelectDropdownComponent,
	ModalDialogComponent,
	MultilineTextComponent,
	InfoboxNoContentComponent,
	EditTableDataserviceLinkComponent,
	EditTableChannelLinkComponent,
	EditTableQualifiedAttributionComponent,
	EditTableQualifiedRelationComponent,
	TableAnnotationComponent,
	EditTableAnnotationComponent,
	ModalDialogAnnotationComponent,
	ModalDialogCodeListComponent,
	SearchFiltersComponent,
	StatusComponent
];
const PIPES = [ArrayToStringPipe, FallbackPipe, FallbackArrayToStringPipe, FileSizePipe, IsInvalidAndUntouchedPipe, PersonSearchPipe];

@NgModule({
	declarations: [...COMPONENTS, ...PIPES],
	imports: [...MODULES, RouterModule],
	exports: [...COMPONENTS, ...PIPES, ...MODULES],
	providers: [
		...PIPES,
		{
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: {appearance: 'outline'}
		},
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE]
		},
		{
			provide: MAT_DATE_FORMATS,
			useValue: {
				parse: {
					dateInput: 'DD.MM.YYYY'
				},
				display: {
					dateInput: 'DD.MM.YYYY',
					monthYearLabel: 'MMM YYYY',
					dateA11yLabel: 'LL',
					monthYearA11yLabel: 'MMMM YYYY'
				}
			}
		}
	]
})
export class SharedModule {}
