import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ObUnknownRouteModule} from '@oblique/oblique';
import {HomeComponent} from './home/home.component';
import {UnauthorizedComponent} from './unauthorized/unauthorized.component';
import {CatalogComponent} from './catalog/catalog.component';
import {AuthChildGuard, AuthGuard} from './auth/auth.guard';
import {LoginGuard} from './auth/login.guard';

const routes: Routes = [
	{path: '', redirectTo: 'home', pathMatch: 'full'},
	{path: 'unauthorized', component: UnauthorizedComponent},
	{path: 'home', component: HomeComponent, canActivate: [AuthGuard]},
	{path: 'login', children: [], canActivate: [LoginGuard]},
	{path: 'catalog', component: CatalogComponent, canActivate: [AuthGuard]},
	{
		path: 'catalog/datasets',
		loadChildren: () => import('./datasets/datasets.module').then(m => m.DatasetsModule),
		canActivate: [AuthGuard],
		canActivateChild: [AuthChildGuard]
	},
	{
		path: 'catalog/dataservices',
		loadChildren: () => import('./dataservices/dataservices.module').then(m => m.DataservicesModule),
		canActivate: [AuthGuard],
		canActivateChild: [AuthChildGuard]
	},
	{
		path: 'catalog/publicservices',
		loadChildren: () => import('./publicservices/publicservices.module').then(m => m.PublicServicesModule),
		canActivate: [AuthGuard],
		canActivateChild: [AuthChildGuard]
	},
	{
		path: 'concepts',
		loadChildren: () => import('./concepts/concepts.module').then(m => m.ConceptsModule),
		canActivate: [AuthGuard],
		canActivateChild: [AuthChildGuard]
	},
	{path: '**', redirectTo: 'unknown-route'}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'}), ObUnknownRouteModule],
	exports: [RouterModule]
})
export class AppRoutingModule {}
