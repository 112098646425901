<ob-alert type="warning" *ngIf="showWarning">
	{{ warningText }}
</ob-alert>
<ob-alert type="info" *ngIf="showInfo">
	{{ infoText }}
</ob-alert>

<div class="status-container" *ngIf="registrationStatus?.status || registrationStatus?.proposal">
	<div class="status-block" *ngIf="enableVersioning">
		<div class="status-title">
			{{ "i18n.page.datasets.description.edit.version" | translate }}
		</div>
		<div class="status-content">
			<div class="status-wrapper">
				<div class="status-label">{{ "i18n.shared.status.current" | translate }}</div>
				<div class="status-value">
					<div>{{ version ?? "-" }}</div>
				</div>
			</div>
		</div>
		<div class="status-actions">
			<button type="button" mat-button obButton="secondary" [routerLink]="['newversion']" [disabled]="disbaleNewVersion$ | async">
				{{ "i18n.page.dataset.button.create.newversion" | translate }}
			</button>
		</div>
	</div>

	<div class="status-block">
		<div class="status-title">
			<div>{{ "i18n.shared.status.registrationstatus.title" | translate }}</div>
		</div>
		<div class="status-content">
			<div class="status-wrapper" *ngIf="registrationStatus?.status">
				<div class="status-label">{{ "i18n.shared.status.current" | translate }}</div>
				<div class="status-value">
					<div [ngClass]="registrationStatus?.status === 'Incomplete' ? 'status-chip status-chip-warning' : 'status-chip status-chip-success'">
						<mat-chip-list>
							<mat-chip>{{ "i18n.shared.status.registrationstatus." + registrationStatus?.status?.toLowerCase()?.replace(" ", "") | translate }}</mat-chip>
						</mat-chip-list>
					</div>
				</div>
			</div>
			<div class="status-wrapper" *ngIf="registrationStatus?.proposal">
				<div class="status-label">{{ "i18n.shared.status.proposed" | translate }}</div>
				<div class="status-value">
					<div class="status-chip status-chip-info">
						<mat-chip-list>
							<mat-chip>{{ "i18n.shared.status.registrationstatus." + registrationStatus?.proposal?.toLowerCase()?.replace(" ", "") | translate }}</mat-chip>
						</mat-chip-list>
					</div>
				</div>
			</div>
		</div>
		<div class="status-actions">
			<button
				*ngIf="showDropdownButton(registrationStatus?.allowedStatuses)"
				mat-button
				obButton="secondary"
				#setRegStatMenuTrigger="matMenuTrigger"
				[matMenuTriggerFor]="setRegStatMenu"
			>
				{{ "i18n.shared.status.set.registrationstatus.button" | translate }}
				<mat-icon svgIcon="{{ setRegStatMenuTrigger.menuOpen ? 'chevron-up' : 'chevron-down' }}"></mat-icon>
			</button>
			<mat-menu #setRegStatMenu="matMenu">
				<button
					mat-button
					class="menu-button"
					*ngFor="let allowedStatus of registrationStatus?.allowedStatuses"
					(click)="onSetRegistrationStatusClick(allowedStatus)"
				>
					{{ "i18n.shared.status.registrationstatus." + allowedStatus.toLowerCase().replace(" ", "") | translate }}
				</button>
			</mat-menu>
			<button
				*ngIf="showDropdownButton(registrationStatus?.allowedProposals)"
				mat-button
				obButton="secondary"
				#propRegStatMenuTrigger="matMenuTrigger"
				[matMenuTriggerFor]="propRegStatMenu"
			>
				{{ "i18n.shared.status.propose.registrationstatus.button" | translate }}
				<mat-icon svgIcon="{{ propRegStatMenuTrigger.menuOpen ? 'chevron-up' : 'chevron-down' }}"></mat-icon>
			</button>
			<mat-menu #propRegStatMenu="matMenu">
				<button
					mat-button
					class="menu-button"
					*ngFor="let allowedProposal of registrationStatus?.allowedProposals"
					(click)="onProposeRegistrationStatusClick(allowedProposal)"
				>
					{{ "i18n.shared.status.registrationstatus." + allowedProposal.toLowerCase().replace(" ", "") | translate }}
				</button>
			</mat-menu>
		</div>
	</div>

	<div class="status-block" *ngIf="publicationLevel?.level || publicationLevel?.proposal">
		<div class="status-title">
			<div>{{ "i18n.shared.status.publicationlevel.title" | translate }}</div>
		</div>
		<div class="status-content">
			<div class="status-wrapper" *ngIf="publicationLevel?.level">
				<div class="status-label">{{ "i18n.shared.status.current" | translate }}</div>
				<div class="status-value">
					<div class="status-chip status-chip-info">
						<mat-chip-list>
							<mat-chip>{{ "i18n.shared.status.publicationlevel." + publicationLevel?.level?.toLowerCase()?.replace(" ", "") | translate }}</mat-chip>
						</mat-chip-list>
					</div>
				</div>
			</div>
			<div class="status-wrapper" *ngIf="publicationLevel?.proposal">
				<div class="status-label">{{ "i18n.shared.status.proposed" | translate }}</div>
				<div class="status-value">
					<div class="status-chip status-chip-info">
						<mat-chip-list>
							<mat-chip>{{ "i18n.shared.status.publicationlevel." + publicationLevel?.proposal?.toLowerCase()?.replace(" ", "") | translate }}</mat-chip>
						</mat-chip-list>
					</div>
				</div>
			</div>
		</div>
		<div class="status-actions">
			<button
				*ngIf="showDropdownButton(publicationLevel?.allowedLevels)"
				mat-button
				obButton="secondary"
				#setPubLevelMenuTrigger="matMenuTrigger"
				[matMenuTriggerFor]="setPubLevelMenu"
			>
				{{ "i18n.shared.status.set.publicationlevel.button" | translate }}
				<mat-icon svgIcon="{{ setPubLevelMenuTrigger.menuOpen ? 'chevron-up' : 'chevron-down' }}"></mat-icon>
			</button>

			<mat-menu #setPubLevelMenu="matMenu">
				<button mat-button class="menu-button" *ngFor="let allowedLevel of publicationLevel?.allowedLevels" (click)="onSetPublicationLevelClick(allowedLevel)">
					{{ "i18n.shared.status.publicationlevel." + allowedLevel.toLowerCase().replace(" ", "") | translate }}
				</button>
			</mat-menu>

			<button
				*ngIf="showDropdownButton(publicationLevel?.allowedProposals)"
				mat-button
				obButton="secondary"
				#propPubLevelMenuTrigger="matMenuTrigger"
				[matMenuTriggerFor]="propPubLevelMenu"
			>
				{{ "i18n.shared.status.propose.publicationlevel.button" | translate }}
				<mat-icon svgIcon="{{ propPubLevelMenuTrigger.menuOpen ? 'chevron-up' : 'chevron-down' }}"></mat-icon>
			</button>

			<mat-menu #propPubLevelMenu="matMenu">
				<button
					mat-button
					class="menu-button"
					*ngFor="let allowedProposal of publicationLevel?.allowedProposals"
					(click)="onProposePublicationLevelClick(allowedProposal)"
				>
					{{ "i18n.shared.status.publicationlevel." + allowedProposal.toLowerCase().replace(" ", "") | translate }}
				</button>
			</mat-menu>
		</div>

		<div class="status-content">
			<a *ngIf="publicationLevel?.level === 'I14Y Public'" href="{{ redirectToExternalResource() }}" mat-button obButton="tertiary">{{
				"i18n.shared.status.publicationlevel.link.title" | translate
			}}</a>
		</div>
	</div>
</div>
