import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import {CodelistInputClient, MultiLanguage} from '@bfs-sis/bfs-iop-admin-web-api-client';
import {createMultilangValidator} from '../../validators/multilang.validator';
import {Observable, of} from 'rxjs';
import {createWhitespaceValidator} from '../../validators/whitespace.validator';

@Component({
	selector: 'app-modal-dialog-codelist',
	templateUrl: './modal-dialog.component.html'
})
export class ModalDialogCodeListComponent implements OnInit {
	form!: UntypedFormGroup;

	searchCodeListEntries$!: Observable<string[]>;

	private selectedParentCodeValue: string | undefined = undefined;

	constructor(
		private readonly codelistInputClient: CodelistInputClient,
		private readonly dialogRef: MatDialogRef<ModalDialogCodeListComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	ngOnInit(): void {
		this.form = new UntypedFormGroup({
			value: new UntypedFormControl('', [Validators.required, createWhitespaceValidator()]),
			parentCode: new UntypedFormControl(''),
			name: new UntypedFormGroup(
				this.getObjectFromKeys(this.data.contentLanguages, () => new UntypedFormControl()),
				{
					validators: [createMultilangValidator([...this.data.contentLanguages])]
				}
			),
			description: new UntypedFormGroup(this.getObjectFromKeys(this.data.contentLanguages, () => new UntypedFormControl()))
		});

		this.mapDataToForm();
	}

	get isSaveDisabled(): boolean {
		return !this.form.dirty;
	}

	onParentCodeInputChanged(query: string): void {
		if (this.data.codeListEntry.codelistId && query.length > 0) {
			// eslint-disable-next-line max-len
			this.codelistInputClient.getCodelistEntriesSearchByIdAndSearchStringAndMaxCount(this.data.codeListEntry.codelistId, query, 20).subscribe(response => {
				this.searchCodeListEntries$ = of(response.result.map(entry => entry.value ?? ''));
			});
		}
	}

	onParentCodeSelection(parentCodeValue: string) {
		this.selectedParentCodeValue = parentCodeValue;
	}

	save() {
		this.mapFormToData();
		this.dialogRef.close(this.data);
	}

	private getObjectFromKeys<Type>(keys: readonly string[], initialValue: (key: string) => Type) {
		return Object.assign({}, ...keys.map(x => ({[x]: initialValue(x)})));
	}

	private mapDataToForm(): void {
		this.form.patchValue({
			value: this.data.codeListEntry.value,
			parentCode: this.data.codeListEntry.parentCode,
			name: new MultiLanguage(this.data.codeListEntry.name),
			description: this.data.codeListEntry.description
		});
	}

	private mapFormToData(): void {
		this.data.codeListEntry.value = this.form.value.value;
		this.data.codeListEntry.parentCode = this.selectedParentCodeValue;
		this.data.contentLanguages.forEach((l: string) => {
			this.data.codeListEntry.name![l as keyof MultiLanguage] = this.form.value.name[l] || undefined;
			this.data.codeListEntry.description![l as keyof MultiLanguage] = this.form.value.description[l] || undefined;
		});
	}
}
