import {SelectionModel} from '@angular/cdk/collections';
import {AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {Annotation, CodelistEntryInputClient, MultiLanguage} from '@bfs-sis/bfs-iop-admin-web-api-client';
import {TranslateService} from '@ngx-translate/core';
import {Languages} from '../ApplicationLanguage.enum';
import {FallbackPipe} from '../fallback/fallback.pipe';
import {MultiLanguageMapper} from '../mappers/multilanguagemapper';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig} from '@angular/material/legacy-dialog';
import {ModalDialogAnnotationComponent} from './modal-dialog/modal-dialog.component';
import {ObNotificationService} from '@oblique/oblique';

@Component({
	selector: 'app-edit-table-annotation',
	templateUrl: './edit-table-annotation.component.html',
	styleUrls: ['./edit-table-annotation.component.scss']
})
export class EditTableAnnotationComponent implements AfterViewInit, OnChanges {
	@ViewChild(MatSort, {static: false}) sort!: MatSort;
	@Input() id!: string;
	@Input() annotations: Annotation[] = [];
	@Input() currentLanguage!: string;
	@Input() controlName!: string;
	@Output() reloadEntriesEvent = new EventEmitter();

	dataSource = new MatTableDataSource<Annotation>(this.annotations);

	COLUMN_SELECT = 'select';
	COLUMN_TYPE = 'type';
	COLUMN_TITLE = 'title';
	COLUMN_TEXT = 'text';
	COLUMN_URI = 'uri';
	COLUMN_IDENTIFIER = 'identifier';
	COLUMN_ACTIONS = 'actions';

	contentLanguages: readonly string[] = Languages.ContentLanguagesRm;

	displayedColumns: string[] = [
		this.COLUMN_SELECT,
		this.COLUMN_TYPE,
		this.COLUMN_TITLE,
		this.COLUMN_TEXT,
		this.COLUMN_URI,
		this.COLUMN_IDENTIFIER,
		this.COLUMN_ACTIONS
	];

	private selection = new SelectionModel<Annotation>(true, []);

	constructor(
		readonly translate: TranslateService,
		private readonly codelistEntryInputClient: CodelistEntryInputClient,
		private readonly fallbackPipe: FallbackPipe,
		public dialog: MatDialog,
		private readonly notification: ObNotificationService
	) {
		this.currentLanguage = this.translate.currentLang;
	}

	ngAfterViewInit(): void {
		this.dataSource.sort = this.sort;
		this.sort.sortChange.subscribe(() => {
			this.sortpage();
		});
	}
	ngOnChanges(changes: SimpleChanges): void {
		if (changes.annotations) {
			if (this.annotations) {
				this.dataSource = new MatTableDataSource<Annotation>(this.annotations);
			}
		}
	}

	canAdd(): boolean {
		return !this.hasSelectedItems();
	}

	onEditRow(row: Annotation): void {
		this.showDialog(row);
	}

	onAddRow(): void {
		this.showDialog(this.newEntry());
	}

	onRemoveSelectedRows(): void {
		this.selection.selected.forEach(item => {
			let index: number = this.dataSource.data.findIndex((d: Annotation) => d === item);
			this.onRemoveRow(index);
		});
		this.selection = new SelectionModel<Annotation>(true, []);
	}

	onRemoveRow(index: number): void {
		const annotationId = this.dataSource.data[index].id;
		if (annotationId) {
			this.codelistEntryInputClient.deleteAnnotationsByIdAndAnnotationId(this.id, annotationId).subscribe(x => {
				if (x.status === 204) {
					this.dataSource.data.splice(index, 1);
					this.reloadEntriesEvent.emit();
				} else {
					this.showErrorNotification();
				}
			});
		}
	}

	onMasterToggle(): void {
		if (this.isAllSelected()) {
			this.selection.clear();
		} else {
			this.dataSource.data.forEach(row => this.selection.select(row));
		}
	}

	onToggleSelection(row: Annotation): void {
		this.selection.toggle(row);
	}

	hasRows(): boolean {
		return this.dataSource?.data?.length > 0;
	}

	hasSelectedRows(): boolean {
		return this.selection.hasValue();
	}

	isAllSelected(): boolean {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource.data.length;
		return numSelected === numRows;
	}

	isRowSelected(row: Annotation): boolean {
		return this.selection.isSelected(row);
	}

	private newEntry(): Annotation {
		return new Annotation({
			identifier: undefined,
			text: MultiLanguageMapper.fixEmptyValues(new MultiLanguage()),
			title: undefined,
			type: undefined,
			uri: undefined
		});
	}
	private sortpage() {
		switch (this.sort?.active) {
			case this.COLUMN_TEXT:
				this.annotations.sort((a, b) => {
					const aText = this.fallbackPipe.transform(a.text, this.currentLanguage);
					const bText = this.fallbackPipe.transform(b.text, this.currentLanguage);
					return this.sortSub(aText!, bText!);
				});
				break;
			case this.COLUMN_TYPE:
				this.annotations.sort((a, b) => {
					return this.sortSub(a.type!.toString(), b.type!.toString());
				});

				break;
			case this.COLUMN_TITLE:
				this.annotations.sort((a, b) => {
					return this.sortSub(a.title!.toString(), b.title!.toString());
				});
				break;
			case this.COLUMN_URI:
				this.annotations.sort((a, b) => {
					return this.sortSub(a.uri!.toString(), b.uri!.toString());
				});
				break;
			case this.COLUMN_IDENTIFIER:
				this.annotations.sort((a, b) => {
					return this.sortSub(a.identifier!.toString(), b.identifier!.toString());
				});
		}

		this.dataSource = new MatTableDataSource<Annotation>(this.annotations);
	}

	private sortSub(a: string, b: string): number {
		if (this.sort.direction === 'asc') {
			return a.toLowerCase().localeCompare(b.toLowerCase());
		} else {
			return b.toLowerCase().localeCompare(a.toLowerCase());
		}
	}

	private hasSelectedItems(): boolean {
		return !this.selection.isEmpty();
	}

	private showDialog(entry: Annotation) {
		const dialogConfig = new MatDialogConfig();

		this.updateDialogConfig(entry, dialogConfig);

		const dialogRef = this.dialog.open(ModalDialogAnnotationComponent, dialogConfig);

		dialogRef.afterClosed().subscribe(data => {
			if (data) {
				if (data.annotation.id) {
					this.updateAnnotation(data);
				} else {
					// bugfix: identifier in sms is not nullabled
					this.createAnnotation(data);
				}
			}
		});
	}

	private createAnnotation(data: any) {
		if (!data.annotation.identifier) {
			data.annotation.identifier = '';
		}
		this.codelistEntryInputClient.postAnnotationsByIdAndBody(this.id, data.annotation).subscribe(x => {
			if (x.status === 201) {
				this.reloadEntriesEvent.emit();
			} else {
				this.showErrorNotification();
			}
		});
	}

	private updateAnnotation(data: any) {
		this.codelistEntryInputClient.putAnnotationsByIdAndAnnotationIdAndBody(this.id, data.annotation.id, data.annotation).subscribe(x => {
			if (x.status === 204) {
				this.reloadEntriesEvent.emit();
			} else {
				this.showErrorNotification();
			}
		});
	}

	private updateDialogConfig(entry: Annotation, dialogConfig: MatDialogConfig<any>) {
		const title = entry.id ? 'Edit Annotation' : 'New Annotation';
		dialogConfig.data = {title: title, contentLanguages: this.contentLanguages, annotation: entry};
		dialogConfig.width = '70%';
		dialogConfig.maxWidth = '1200px';
		dialogConfig.minWidth = '600px';
		dialogConfig.disableClose = false;
		dialogConfig.autoFocus = true;
	}

	private showErrorNotification(): void {
		this.notification.error('i18n.app.notification.save.error');
	}
}
