import {Injectable} from '@angular/core';
import {AllowAction, AllowActionsClient, CatalogType} from '@bfs-sis/bfs-iop-admin-web-api-client';
import {Observable, ReplaySubject, Subject} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AllowActionService {
	readonly globalAllowActions$: Observable<AllowAction[]>;
	readonly allowAction$: Observable<AllowAction>;
	readonly childAllowAction$: Observable<AllowAction>;
	private last: string | undefined;
	private lastChild: string | undefined;

	private readonly globalAllowActions: Subject<AllowAction[]> = new ReplaySubject<AllowAction[]>();
	private readonly allowAction: Subject<AllowAction> = new ReplaySubject<AllowAction>();
	private readonly childAallowAction: Subject<AllowAction> = new ReplaySubject<AllowAction>();

	constructor(private readonly client: AllowActionsClient) {
		this.globalAllowActions$ = this.globalAllowActions.asObservable();
		this.client.get().subscribe(respose => {
			this.globalAllowActions.next(respose.result);
		});
		this.allowAction$ = this.allowAction.asObservable();
		this.allowAction.next(
			new AllowAction({
				allowCreate: false,
				allowDelete: false,
				allowEdit: false,
				allowVersion: false,
				deleteState: undefined,
				message: undefined,
				type: undefined
			})
		);
		this.childAllowAction$ = this.childAallowAction.asObservable();
		this.childAallowAction.next(
			new AllowAction({
				allowCreate: false,
				allowDelete: false,
				allowEdit: false,
				allowVersion: false,
				deleteState: undefined,
				message: undefined,
				type: undefined
			})
		);
	}

	load(id: string, type: CatalogType, force: boolean = false): void {
		if (force || !this.last || this.last !== id) {
			this.client.getByTypeAndId(type, id).subscribe(response => {
				this.allowAction.next(response.result);
				this.last = id;
			});
		}
	}

	loadChild(id: string, type: CatalogType, force: boolean = false): void {
		if (force || !this.lastChild || this.lastChild !== id) {
			this.client.getByTypeAndId(type, id).subscribe(response => {
				this.childAallowAction.next(response.result);
				this.lastChild = id;
			});
		}
	}
}
