import {Injectable} from '@angular/core';
import {
	CodelistInputClient,
	DataElementInputClient,
	DatasetInputClient,
	DataStructureDefinitionInputClient,
	DistributionInputClient
} from '@bfs-sis/bfs-iop-admin-web-api-client';

@Injectable()
export class DatasetInputClientsService {
	constructor(
		private readonly _codelistInputClient: CodelistInputClient,
		private readonly _datasetInputClient: DatasetInputClient,
		private readonly _dataElementInputClient: DataElementInputClient,
		private readonly _dataStructureDefinitionInputClient: DataStructureDefinitionInputClient,
		private readonly _distributionInputClient: DistributionInputClient
	) {}

	get codelistInputClient(): CodelistInputClient {
		return this._codelistInputClient;
	}

	get dataElementInputClient(): DataElementInputClient {
		return this._dataElementInputClient;
	}

	get datasetInputClient(): DatasetInputClient {
		return this._datasetInputClient;
	}

	get dataStructureDefinitionInputClient(): DataStructureDefinitionInputClient {
		return this._dataStructureDefinitionInputClient;
	}

	get distributionInputClient(): DistributionInputClient {
		return this._distributionInputClient;
	}
}
