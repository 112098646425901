<div class="flex-container container">
	<div class="last">
		<h1>{{ "i18n.page.catalog.title" | translate }}</h1>
	</div>
	<div class="button-wrapper">
		<button
			id="catalog-menubutton"
			mat-button
			obButton="secondary"
			#buttonMenuTrigger="matMenuTrigger"
			[matMenuTriggerFor]="buttonMenu"
			[disabled]="(cannotCreateDataset$ | async) && (cannotCreateDataSercive$ | async) && (cannotCreatePubicService$ | async)"
		>
			{{ "i18n.page.catalog.menubutton" | translate }}
			<mat-icon svgIcon="{{ buttonMenuTrigger.menuOpen ? 'chevron-up' : 'chevron-down' }}"></mat-icon>
		</button>
		<mat-menu #buttonMenu="matMenu">
			<a id="create-dataset" mat-button [routerLink]="['datasets', nav_value_create]" [disabled]="cannotCreateDataset$ | async">
				{{ "i18n.page.catalog.button.create.dataset" | translate }}
			</a>
			<a id="create-dataservice" mat-button [routerLink]="['dataservices', nav_value_create]" [disabled]="cannotCreateDataSercive$ | async">
				{{ "i18n.page.catalog.button.create.dataservice" | translate }}
			</a>
			<a id="create-publicservice" mat-button [routerLink]="['publicservices', nav_value_create]" [disabled]="cannotCreatePubicService$ | async">
				{{ "i18n.page.catalog.button.create.publicservice" | translate }}
			</a>
		</mat-menu>
	</div>
</div>

<div class="ob-grid search-section">
	<div class="ob-span-6 search-input-wrapper">
		<mat-form-field>
			<div class="search-input">
				<input
					id="catalog-search"
					type="search"
					name="query"
					matInput
					placeholder="{{ 'i18n.page.catalog.search' | translate }}"
					[(ngModel)]="queryInput"
					(keyup.enter)="search()"
				/>
				<button mat-icon-button [obButton]="'tertiary'" (click)="search()">
					<mat-icon svgIcon="search" class="ob-icon"></mat-icon>
				</button>
				<button mat-icon-button [obButton]="'tertiary'" (click)="onResetQuery()">
					<mat-icon *ngIf="queryInput" svgIcon="cancel" class="ob-icon"></mat-icon>
				</button>
			</div>
		</mat-form-field>
	</div>
	<div class="ob-span-6 filter-buttons">
		<div class="filter-button">
			<button mat-button obButton="secondary" (click)="toggleFilterVisibility()">
				<mat-icon [svgIcon]="'filter'"></mat-icon>
				{{ (hideFilters ? "i18n.shared.filters.show" : "i18n.shared.filters.hide") | translate }}
			</button>
		</div>
	</div>
</div>

<div class="filter-section">
	<app-search-filters [hideFilters]="hideFilters" [searchType]="searchType" [query]="query"></app-search-filters>
</div>

<app-table [dto]="catalogEntry"></app-table>

<mat-paginator
	[length]="pagingInfo.totalRows"
	[pageSizeOptions]="pagingInfo.pageSizeOptions"
	[pageSize]="pagingInfo.pageSize"
	[pageIndex]="pagingInfo.page - 1"
	(page)="onChangePage($event)"
></mat-paginator>
