import {Component, OnInit, OnDestroy} from '@angular/core';
import {NAV_VALUE_CREATE} from '../app-constants';
import {CatalogClient, CatalogEntry, CatalogType, SwaggerResponse} from '@bfs-sis/bfs-iop-admin-web-api-client';
import {SearchResultPagingInfo} from '../shared/searchResultPagingInfo';
import {LegacyPageEvent as PageEvent} from '@angular/material/legacy-paginator';
import {filter, map, Observable, of, startWith, Subject, takeUntil} from 'rxjs';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {AllowActionService} from '../services/allow.action.service';
import {SearchType} from '../shared/search-filters/search-filters';
import {SearchFilterService} from '../shared/search-filters/search-filters.service';

@Component({
	selector: 'app-catalog',
	templateUrl: './catalog.component.html',
	styleUrls: ['./catalog.component.scss']
})
export class CatalogComponent implements OnInit, OnDestroy {
	readonly nav_value_create: string = NAV_VALUE_CREATE;
	queryInput: string = this.route.snapshot.queryParamMap.get('query') ?? '';
	query: string | undefined;
	catalogEntry: CatalogEntry[] = [];
	cannotCreateDataset$: Observable<boolean> = of(true);
	cannotCreateDataSercive$: Observable<boolean> = of(true);
	cannotCreatePubicService$: Observable<boolean> = of(true);
	defaultPageSize: number = 50;
	defaultPage: number = 1;
	pagingInfo: SearchResultPagingInfo = new SearchResultPagingInfo(undefined, this.defaultPageSize);
	hideFilters: boolean = true;
	searchType: SearchType = SearchType.CataLog;

	private readonly unsubscribe$ = new Subject();

	constructor(
		private readonly catalogClient: CatalogClient,
		private readonly router: Router,
		private readonly route: ActivatedRoute,
		private readonly searchFilterService: SearchFilterService,
		private readonly allowActionService: AllowActionService
	) {}

	ngOnInit(): void {
		this.router.events
			.pipe(
				takeUntil(this.unsubscribe$),
				filter((e): e is NavigationEnd => e instanceof NavigationEnd)
			)
			.subscribe(_ => {
				this.queryInput = this.route.snapshot.queryParamMap.get('query') ?? '';
				this.searchCatalog();
			});
		this.searchCatalog();
		this.cannotCreateDataset$ = this.allowActionService.globalAllowActions$.pipe(
			takeUntil(this.unsubscribe$),
			map(result => {
				return !result.find(x => x.type === CatalogType.Dataset)?.allowCreate ?? true;
			}),
			startWith(true)
		);
		this.cannotCreateDataSercive$ = this.allowActionService.globalAllowActions$.pipe(
			takeUntil(this.unsubscribe$),
			map(result => {
				return !result.find(x => x.type === CatalogType.DataService)?.allowCreate ?? true;
			}),
			startWith(true)
		);
		this.cannotCreatePubicService$ = this.allowActionService.globalAllowActions$.pipe(
			takeUntil(this.unsubscribe$),
			map(result => {
				return !result.find(x => x.type === CatalogType.PublicService)?.allowCreate ?? true;
			}),
			startWith(true)
		);
	}

	ngOnDestroy() {
		this.unsubscribe$.next(1);
		this.unsubscribe$.complete();
	}

	onChangePage(pageEvent: PageEvent) {
		this.pagingInfo.pageSize = pageEvent.pageSize;
		this.pagingInfo.page = pageEvent.pageIndex + 1;
		this.searchCatalog();
	}

	search(): void {
		if (this.queryInput) {
			const queryParams = {query: this.queryInput ?? null};
			this.router.navigate([], {
				relativeTo: this.route,
				queryParams,
				queryParamsHandling: 'merge'
			});
		}
	}

	onResetQuery(): void {
		this.queryInput = '';
		this.query = undefined;
		this.router.navigate(['./'], {
			relativeTo: this.route,
			queryParams: {query: null, page: null, pageSize: null},
			queryParamsHandling: 'merge'
		});
	}

	toggleFilterVisibility() {
		this.hideFilters = !this.hideFilters;
	}

	private searchCatalog() {
		const filters = this.searchFilterService.getSelectedFilters(this.searchType);
		this.query = this.queryInput ?? undefined;
		this.catalogClient // eslint-disable-next-line max-len
			.getSearchByQueryAndAccessRightsAndFormatsAndBusinessEventsAndLevelsAndLevelProposalsAndLifeEventsAndPublishersAndStatusesAndStatusProposalsAndThemesAndTypesAndPageAndPageSize(
				this.query ?? undefined,
				filters.accessRights ?? undefined,
				filters.formats ?? undefined,
				filters.businessEvents ?? undefined,
				filters.levels ?? undefined,
				filters.levelProposals ?? undefined,
				filters.lifeEvents ?? undefined,
				filters.publishers ?? undefined,
				filters.statuses ?? undefined,
				filters.statusProposals ?? undefined,
				filters.themes ?? undefined,
				filters.types ?? undefined,
				this.pagingInfo?.page ?? this.defaultPage,
				this.pagingInfo?.pageSize ?? this.defaultPageSize
			)
			.subscribe((response: SwaggerResponse<CatalogEntry[]>) => {
				this.pagingInfo = new SearchResultPagingInfo(response.headers);
				this.catalogEntry = response.result;
			});
	}
}
