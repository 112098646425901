import {Injectable} from '@angular/core';
import {
	AgentClient,
	CodelistInputClient,
	DataElementInputClient,
	DatasetInputClient,
	DataStructureDefinitionInputClient,
	DistributionInputClient,
	SmsLinksClient,
	VocabularyClient
} from '@bfs-sis/bfs-iop-admin-web-api-client';
import {FallbackPipe} from 'src/app/shared/fallback/fallback.pipe';
import {CodelistIdentifierValidator} from 'src/app/shared/validators/codelist-identifier.validator';
import {DatasetIdentifierValidator} from 'src/app/shared/validators/dataset-identifier.validator';
import {StructureIdentifierValidator} from 'src/app/shared/validators/structure-identifier.validator';
import {DatasetClientsService} from './dataset-clients.service';
import {DatasetInputClientsService} from './dataset-input-clients.service';
import {DatasetServicesService} from './dataset-services.service';
import {DatasetStatusService} from './dataset-status.service';
import {DatasetValidatorsService} from './dataset-validators.service';
import {NewVersionDatasetIdentifierValidator} from 'src/app/shared/validators/new-Version-dataset-identifier.validator';

@Injectable()
export class DatasetInjectablesService {
	constructor(
		private readonly _fallbackPipe: FallbackPipe,
		private readonly _datasetClientsService: DatasetClientsService,
		private readonly _datasetInputClientsService: DatasetInputClientsService,
		private readonly _datasetServicesService: DatasetServicesService,
		private readonly _datasetValidatorsService: DatasetValidatorsService
	) {}

	get agentClient(): AgentClient {
		return this._datasetClientsService.agentClient;
	}

	get codelistIdentifierValidator(): CodelistIdentifierValidator {
		return this._datasetValidatorsService.codelistIdentifierValidator;
	}

	get codelistInputClient(): CodelistInputClient {
		return this._datasetInputClientsService.codelistInputClient;
	}

	get dataElementInputClient(): DataElementInputClient {
		return this._datasetInputClientsService.dataElementInputClient;
	}

	get datasetIdentifierValidator(): DatasetIdentifierValidator {
		return this._datasetValidatorsService.datasetIdentifierValidator;
	}

	get newVersionDatasetIdentifierValidator(): NewVersionDatasetIdentifierValidator {
		return this._datasetValidatorsService.newVersionDatasetIdentifierValidator;
	}

	get datasetInputClient(): DatasetInputClient {
		return this._datasetInputClientsService.datasetInputClient;
	}

	get datasetStatusService(): DatasetStatusService {
		return this._datasetServicesService.datasetStatusService;
	}

	get dataStructureDefinitionInputClient(): DataStructureDefinitionInputClient {
		return this._datasetInputClientsService.dataStructureDefinitionInputClient;
	}

	get distributionInputClient(): DistributionInputClient {
		return this._datasetInputClientsService.distributionInputClient;
	}

	get fallbackPipe(): FallbackPipe {
		return this._fallbackPipe;
	}

	get smsLinkController(): SmsLinksClient {
		return this._datasetClientsService.smsLinkController;
	}

	get structureIdentifierValidator(): StructureIdentifierValidator {
		return this._datasetValidatorsService.structureIdentifierValidator;
	}

	get vocabularyClient(): VocabularyClient {
		return this._datasetClientsService.vocabularyClient;
	}
}
