import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {FallbackPipe} from '../fallback/fallback.pipe';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {ConceptInputClient, DataElementReference} from '@bfs-sis/bfs-iop-admin-web-api-client';

@Component({
	selector: 'app-concept-relation-table',
	templateUrl: './concept-relation-table.component.html',
	styleUrls: ['./concept-relation-table.component.scss']
})
export class ConceptRelationTableComponent implements OnInit, OnDestroy {
	@Input() conceptId: string | undefined;
	dataSource = new MatTableDataSource<DataElementReference>([]);

	currentLanguage: string;

	COLUMN_TITLE = 'dataset';
	COLUMN_ENDPPOINTURLS = 'dataStructureDefinitionId';
	COLUMN_VERSION = 'publisher';

	displayedColumns: string[] = [this.COLUMN_TITLE, this.COLUMN_ENDPPOINTURLS, this.COLUMN_VERSION];

	private readonly unsubscribe$ = new Subject();

	constructor(
		private readonly translate: TranslateService,
		private readonly fallbackPipe: FallbackPipe,
		private readonly conceptInputClient: ConceptInputClient
	) {
		this.currentLanguage = this.translate.currentLang;
		this.translate.onLangChange.pipe(takeUntil(this.unsubscribe$)).subscribe((language: LangChangeEvent) => (this.currentLanguage = language.lang));
	}

	ngOnInit() {
		this.translate.onLangChange.pipe(takeUntil(this.unsubscribe$)).subscribe((language: LangChangeEvent) => {
			this.currentLanguage = language.lang;
		});

		if (this.conceptId) {
			this.conceptInputClient
				.getDataElementReferencesById(this.conceptId)
				.subscribe(response => (this.dataSource = new MatTableDataSource<DataElementReference>(response.result)));
		}
	}

	ngOnDestroy() {
		this.unsubscribe$.next(1);
		this.unsubscribe$.complete();
	}
}
