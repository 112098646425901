<div>
	<table mat-table matSort [dataSource]="dataSource" aria-label="table">
		<ng-container [matColumnDef]="COLUMN_SELECT">
			<th mat-header-cell *matHeaderCellDef>
				<mat-checkbox
					(change)="$event ? onMasterToggle() : null"
					[checked]="hasSelectedRows() && isAllSelected()"
					[indeterminate]="hasSelectedRows() && !isAllSelected()"
					matTooltip="{{ 'i18n.app.edittable.checkbox.all.tooltip' | translate }}"
				>
				</mat-checkbox>
			</th>
			<td mat-cell *matCellDef="let row">
				<mat-checkbox
					(click)="$event.stopPropagation()"
					(change)="$event ? onToggleSelection(row) : null"
					[checked]="isRowSelected(row)"
					matTooltip="{{ 'i18n.app.edittable.checkbox.single.tooltip' | translate }}"
				>
				</mat-checkbox>
			</td>
		</ng-container>

		<ng-container [matColumnDef]="COLUMN_ACTIONS">
			<th mat-header-cell *matHeaderCellDef>
				<button
					obButton="secondary"
					mat-icon-button
					matTooltip="{{ 'i18n.app.edittable.button.deleteSelected.tooltip' | translate }}"
					(click)="onRemoveSelectedRows()"
					[disabled]="hasSelectedRows() === false"
				>
					<mat-icon svgIcon="trash" class="ob-icon"></mat-icon>
				</button>
				<button
					obButton="secondary"
					mat-icon-button
					(click)="onAddRow()"
					[disabled]="!canAdd()"
					matTooltip="{{ 'i18n.app.edittable.button.addrow.tooltip' | translate }}"
				>
					<mat-icon svgIcon="plus" class="ob-icon"></mat-icon>
				</button>
			</th>
			<td mat-cell *matCellDef="let row; let i = index">
				<button
					obButton="secondary"
					mat-icon-button
					[disabled]="hasSelectedRows()"
					(click)="onEditRow(row)"
					matTooltip="{{ 'i18n.app.edittable.button.edit.tooltip' | translate }}"
				>
					<mat-icon svgIcon="pen" class="ob-icon"></mat-icon>
				</button>

				<button
					obButton="secondary"
					mat-icon-button
					[disabled]="hasSelectedRows()"
					(click)="onRemoveRow(i)"
					matTooltip="{{ 'i18n.app.edittable.button.remove.tooltip' | translate }}"
				>
					<mat-icon svgIcon="trash" class="ob-icon"></mat-icon>
				</button>
			</td>
		</ng-container>

		<ng-container [matColumnDef]="COLUMN_TYPE">
			<th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "i18n.shared.annotation.type.title" | translate }}</th>
			<td mat-cell *matCellDef="let element">
				<div class="cell">
					{{ element?.type }}
				</div>
			</td>
		</ng-container>
		<ng-container [matColumnDef]="COLUMN_TITLE">
			<th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "i18n.shared.annotation.title.title" | translate }}</th>
			<td class="cell" mat-cell *matCellDef="let element">
				{{ element?.title }}
			</td>
		</ng-container>
		<ng-container [matColumnDef]="COLUMN_TEXT">
			<th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "i18n.shared.annotation.text.title" | translate }}</th>
			<td mat-cell *matCellDef="let element">
				<app-multiline-text [text]="(element.text | fallback: currentLanguage) ?? '-'"></app-multiline-text>
			</td>
		</ng-container>
		<ng-container [matColumnDef]="COLUMN_URI">
			<th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "i18n.shared.annotation.uri.title" | translate }}</th>
			<td mat-cell *matCellDef="let element">
				{{ element?.uri }}
			</td>
		</ng-container>
		<ng-container [matColumnDef]="COLUMN_IDENTIFIER">
			<th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "i18n.shared.annotation.identifier.title" | translate }}</th>
			<td class="cell" mat-cell *matCellDef="let element">
				{{ element?.identifier }}
			</td>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
	</table>

	<div class="table-info-box">
		<app-infobox-no-content [datasource]="dataSource.data"></app-infobox-no-content>
	</div>
</div>
