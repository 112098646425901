import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {CatalogEntry, ICatalogEntry} from '@bfs-sis/bfs-iop-admin-web-api-client';
import {SortableListViewComponent} from '../../shared/sortable-list-view/sortable-list-view.component';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {SearchResultPagingInfo} from '../../shared/searchResultPagingInfo';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';

type SortableKeys = 'title' | 'identifiers' | 'publisherName' | 'registrationStatus' | 'publicationLevel';

@Component({
	selector: 'app-table',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.scss']
})
export class TableComponent extends SortableListViewComponent<ICatalogEntry, SortableKeys> implements OnInit, OnDestroy, OnChanges {
	@Input() dto!: CatalogEntry[];

	readonly COLUMN_TITLE = 'title';
	readonly COLUMN_IDENTIFIERS = 'identifiers';
	readonly COLUMN_PUBLISHERNAME = 'publisherName';
	readonly COLUMN_STATUS = 'registrationStatus';
	readonly COLUMN_PUBLICATION = 'publicationLevel';
	readonly COLUMN_TYPE = 'type';
	readonly COLUMN_ACTIONS = 'actions';

	displayedColumns: string[] = [
		this.COLUMN_TITLE,
		this.COLUMN_IDENTIFIERS,
		this.COLUMN_PUBLISHERNAME,
		this.COLUMN_TYPE,
		this.COLUMN_STATUS,
		this.COLUMN_PUBLICATION,
		this.COLUMN_ACTIONS
	];

	currentLanguage: string;
	pagingInfo: SearchResultPagingInfo = new SearchResultPagingInfo(undefined);
	dataSource = new MatTableDataSource<ICatalogEntry>([]);

	private readonly unsubscribe$ = new Subject();

	constructor(readonly translate: TranslateService) {
		super(translate);

		this.currentLanguage = this.translate.currentLang;
	}

	ngOnInit(): void {
		this.translate.onLangChange.pipe(takeUntil(this.unsubscribe$)).subscribe((language: LangChangeEvent) => {
			this.currentLanguage = language.lang;
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		const change = changes.dto;
		if (change.currentValue !== undefined) {
			this.data = this.dto;
		}
	}

	ngOnDestroy() {
		this.unsubscribe$.next(1);
		this.unsubscribe$.complete();
	}

	isDataSet(catalogEntry: CatalogEntry): boolean {
		return catalogEntry.type === 'Dataset';
	}

	isDataService(catalogEntry: CatalogEntry): boolean {
		return catalogEntry.type === 'DataService';
	}

	getDetailUrl(catalogEntry: CatalogEntry) {
		if (this.isDataSet(catalogEntry)) {
			return `datasets/${catalogEntry.id}`;
		} else if (this.isDataService(catalogEntry)) {
			return `dataservices/${catalogEntry.id}`;
		}
		return `publicservices/${catalogEntry.id}`;
	}
}
