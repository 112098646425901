<ob-master-layout [navigation]="navigation">
	<ng-container obHeaderTitle>{{ "i18n.header.title" | translate }}</ng-container>
	<ng-template #obHeaderControl>
		<div class="header-button-wrapper">
			<button mat-icon-button obButton="secondary" [obPopover]="userInformationPopover" placement="bottom" *ngIf="isLoggedIn | async" id="user">
				<mat-icon svgIcon="user"></mat-icon>
			</button>
			<ng-template #userInformationPopover>
				<div class="userinformation-popover">
					<section class="userinformation-info">
						<div class="userinformation-name">{{ token?.given_name }} {{ token?.family_name }}</div>
						<div class="userinformation-text">{{ token?.email }}</div>
					</section>
					<hr />
					<section class="userinformation-business-role">
						<div class="userinformation-heading">{{ "i18n.home.information.popover.business.role" | translate }}</div>
						<div *ngFor="let item of validatedBusinessRoles.slice(0, businessRoles); let i = index" [attr.data-index]="i">
							<div class="userinformation-text">{{ item }}</div>
						</div>
						<div (click)="showAllBusinessRoles()" class="userinformation-more-text">
							<span [hidden]="!(validatedBusinessRoles.length > 3) || !showMoreButton">{{ "i18n.home.information.popover.display.more" | translate }}</span>
						</div>
					</section>

					<section class="userinformation-agent">
						<div class="userinformation-heading">{{ "i18n.home.information.popover.agent" | translate }}</div>
						<div *ngFor="let item of agents">
							<div class="userinformation-text">{{ item?.name | fallback: currentLanguage }}</div>
						</div>
					</section>

					<section class="userinformation-access-token">
						<div class="userinformation-heading">{{ "i18n.home.information.popover.access.token" | translate }}</div>
						<button
							id="access-token"
							type="button"
							mat-button
							obButton="primary"
							matTooltip="{{ 'i18n.home.information.popover.copy.clipboard.button' | translate }}"
							[cdkCopyToClipboard]="access_token"
						>
							<mat-icon svgIcon="duplicate"></mat-icon>
							{{ "i18n.home.information.popover.copy.clipboard.button" | translate }}
						</button>
					</section>
				</div>
			</ng-template>
			<button
				id="login"
				mat-icon-button
				obButton="secondary"
				matTooltip="{{ 'i18n.button.login.tooltip' | translate }}"
				(click)="login()"
				*ngIf="(isLoggedIn | async) !== true"
			>
				<mat-icon svgIcon="login"></mat-icon>
			</button>
			<button
				id="logout"
				mat-icon-button
				obButton="secondary"
				matTooltip="{{ 'i18n.button.logout.tooltip' | translate }}"
				(click)="logout()"
				*ngIf="isLoggedIn | async"
			>
				<mat-icon svgIcon="logout"></mat-icon>
			</button>
		</div>
	</ng-template>
	<div obFooterInfo>&copy; {{ currentYear }} {{ "i18n.footer.copyright" | translate }}</div>

	<ng-template #obFooterLink>
		<a href="{{ 'i18n.footer.legal.link' | translate }}" target="_blank" title="{{ 'i18n.footer.legal.text' | translate }}" class="footer-link">{{
			"i18n.footer.legal.text" | translate
		}}</a>
	</ng-template>
</ob-master-layout>
