import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {AppConfig} from './app.config';
import {AppRoutingModule} from './app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {CatalogComponent} from './catalog/catalog.component';
import {environment} from './../environments/environment';
import {HomeComponent} from './home/home.component';
import {UnauthorizedComponent} from './unauthorized/unauthorized.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {IAppConfig} from './app.config.interface';
import {IOP_ADMIN_API_BASE_URL} from '@bfs-sis/bfs-iop-admin-web-api-client';
import {KEYCLOAK_CLIENT_ID, KEYCLOAK_URL} from './services/session.service';
import {LoggerModule, NgxLoggerLevel} from 'ngx-logger';
import {MatLegacyPaginatorIntl as MatPaginatorIntl} from '@angular/material/legacy-paginator';
import {MatPaginatorIntlMultiLang} from './shared/matpaginatorintl.multilang';
import {OAuthModule, OAuthModuleConfig, OAuthStorage} from 'angular-oauth2-oidc';
import {ObHttpApiInterceptor, ObIconModule, ObMasterLayoutConfig, multiTranslateLoader, ObNotificationConfig} from '@oblique/oblique';
import {registerLocaleData} from '@angular/common';
import {SharedModule} from './shared/shared.module';
import {TableComponent} from './catalog/table/table.component';
import {TranslateModule} from '@ngx-translate/core';
import localeDECH from '@angular/common/locales/de-CH';
import localeFRCH from '@angular/common/locales/fr-CH';
import localeITCH from '@angular/common/locales/it-CH';
import localeENGB from '@angular/common/locales/en-GB';
import {DashboardComponent} from './home/dashboard/dashboard.component';
import {DatasetInjectablesService} from './datasets/services/dataset-injectables.service';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {DataserviceInjectablesService} from './dataservices/services/dataservice-injectables.service';
import {DataserviceStatusService} from './dataservices/services/dataservice-status.service';

registerLocaleData(localeDECH);
registerLocaleData(localeFRCH);
registerLocaleData(localeITCH);
registerLocaleData(localeENGB);

export function storageFactory(): OAuthStorage {
	return localStorage;
}

export function loadAppConfig() {
	return () =>
		AppConfig.loadConfig('assets/config/appconfig.json').catch(e =>
			/* eslint-disable no-console */
			console.debug(e)
		);
}

export function authConfigFactory(): OAuthModuleConfig {
	const baseUrl = AppConfig.getConfig<IAppConfig>().API_BASE_URL;
	return {
		resourceServer: {
			allowedUrls: [baseUrl],
			sendAccessToken: true
		}
	};
}

@NgModule({
	declarations: [AppComponent, CatalogComponent, DashboardComponent, HomeComponent, TableComponent, UnauthorizedComponent],
	imports: [
		ClipboardModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		BrowserModule,
		HttpClientModule,
		LoggerModule.forRoot({
			level: environment.production ? NgxLoggerLevel.ERROR : NgxLoggerLevel.DEBUG
		}),
		OAuthModule.forRoot(),
		ObIconModule.forRoot(),
		SharedModule,
		TranslateModule.forRoot(multiTranslateLoader())
	],
	providers: [
		DataserviceInjectablesService,
		DataserviceStatusService,
		DatasetInjectablesService,
		{
			provide: APP_INITIALIZER,
			useFactory: () => loadAppConfig(),
			multi: true
		},
		{provide: LOCALE_ID, useValue: 'de-CH'},
		{provide: HTTP_INTERCEPTORS, useClass: ObHttpApiInterceptor, multi: true},
		{provide: MatPaginatorIntl, useClass: MatPaginatorIntlMultiLang},
		{provide: OAuthStorage, useFactory: storageFactory},
		{provide: OAuthModuleConfig, useFactory: authConfigFactory},
		{
			provide: KEYCLOAK_URL,
			useFactory: () => AppConfig.getConfig<IAppConfig>().KEYCLOAK_AUTHORITY_URL
		},
		{
			provide: KEYCLOAK_CLIENT_ID,
			useFactory: () => AppConfig.getConfig<IAppConfig>().KEYCLOAK_CLIENT_ID
		},
		{
			provide: IOP_ADMIN_API_BASE_URL,
			useFactory: () => AppConfig.getConfig<IAppConfig>().API_BASE_URL
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor(config: ObMasterLayoutConfig, private readonly notificationConfig: ObNotificationConfig) {
		this.notificationConfig.sticky = false;
		this.notificationConfig.error = {title: 'i18n.oblique.notification.type.error', sticky: false};

		config.locale.locales = ['de-CH', 'fr-CH', 'it-CH', 'en-GB'];
		config.layout.hasMaxWidth = true;
	}
}
