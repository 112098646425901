<div>
	<table mat-table matSort [dataSource]="dataSource" multiTemplateDataRows>
		<ng-container [matColumnDef]="COLUMN_SELECT">
			<th mat-header-cell *matHeaderCellDef>
				<mat-checkbox
					(change)="$event ? onMasterToggle() : null"
					[checked]="hasSelectedRows() && isAllSelected()"
					[indeterminate]="hasSelectedRows() && !isAllSelected()"
					matTooltip="{{ 'i18n.app.edittable.checkbox.all.tooltip' | translate }}"
				>
				</mat-checkbox>
			</th>
			<td mat-cell *matCellDef="let row">
				<mat-checkbox
					(click)="$event.stopPropagation()"
					(change)="$event ? onToggleSelection(row) : null"
					[checked]="isRowSelected(row)"
					matTooltip="{{ 'i18n.app.edittable.checkbox.single.tooltip' | translate }}"
				>
				</mat-checkbox>
			</td>
		</ng-container>

		<ng-container [matColumnDef]="COLUMN_ACTIONS">
			<th mat-header-cell *matHeaderCellDef>
				<button
					id="codelist-remove-selected-rows"
					obButton="secondary"
					mat-icon-button
					matTooltip="{{ 'i18n.app.edittable.button.deleteSelected.tooltip' | translate }}"
					(click)="onRemoveSelectedRows()"
					[disabled]="hasSelectedRows() === false"
					*ngIf="canCreateCodeValues()"
				>
					<mat-icon svgIcon="trash" class="ob-icon"></mat-icon>
				</button>
				<button
					id="codelist-add-row"
					obButton="secondary"
					mat-icon-button
					(click)="addRow()"
					[disabled]="!canAdd()"
					matTooltip="{{ 'i18n.app.edittable.button.addrow.tooltip' | translate }}"
					*ngIf="canCreateCodeValues()"
				>
					<mat-icon svgIcon="plus" class="ob-icon"></mat-icon>
				</button>
			</th>
			<td mat-cell *matCellDef="let element; let row">
				<button
					id="codelist-edit-row"
					obButton="secondary"
					mat-icon-button
					(click)="editRow(element)"
					[disabled]="hasSelectedRows()"
					matTooltip="{{ 'i18n.app.edittable.button.edit.tooltip' | translate }}"
				>
					<mat-icon svgIcon="pen" class="ob-icon"></mat-icon>
				</button>
				<button
					id="codelist-remove-row"
					obButton="secondary"
					mat-icon-button
					(click)="removeRow(onFindIndex(row))"
					[disabled]="hasSelectedRows()"
					matTooltip="{{ 'i18n.app.edittable.button.remove.tooltip' | translate }}"
				>
					<mat-icon svgIcon="trash" class="ob-icon"></mat-icon>
				</button>
			</td>
		</ng-container>

		<ng-container [matColumnDef]="COLUMN_VALUE">
			<th mat-header-cell mat-sort-header *matHeaderCellDef>
				{{ "i18n.shared.codelist.value" | translate }}
			</th>
			<td mat-cell *matCellDef="let element" [title]="COLUMN_VALUE">
				{{ element?.value }}
			</td>
		</ng-container>

		<ng-container [matColumnDef]="COLUMN_PARENTCODE">
			<th mat-header-cell mat-sort-header *matHeaderCellDef>
				{{ "i18n.shared.codelist.parentcode" | translate }}
			</th>
			<td mat-cell *matCellDef="let element">
				{{ element?.parentCode }}
			</td>
		</ng-container>

		<ng-container *ngFor="let lang of contentLanguages" [matColumnDef]="lang">
			<th mat-header-cell mat-sort-header *matHeaderCellDef>
				{{ "i18n.shared.codelist.codename." + lang | translate }}
			</th>
			<td mat-cell *matCellDef="let element">
				{{ element?.name[lang] }}
			</td>
		</ng-container>

		<ng-container matColumnDef="expand">
			<th mat-header-cell *matHeaderCellDef aria-label="row actions"></th>
			<td mat-cell class="header-chevron" *matCellDef="let element">
				<div mat-icon-button aria-label="expand row" (click)="onExpandRow(element); $event.stopPropagation()">
					<mat-icon *ngIf="expandedElement !== element || !isExpanded" svgIcon="chevron-down" class="ob-icon iconsize"></mat-icon>
					<mat-icon *ngIf="expandedElement === element && isExpanded" svgIcon="chevron-up" class="ob-icon iconsize"></mat-icon>
				</div>
			</td>
		</ng-container>

		<ng-container matColumnDef="expandedDetail">
			<td mat-cell *matCellDef="let element; let row" [attr.colspan]="columnsToDisplayWithExpand.length">
				<div class="element-detail" [@detailExpand]="element === expandedElement && isExpanded ? 'expanded' : 'collapsed'">
					<div class="expand-container">
						<div>
							<div class="font-weight-600 element-detail-row">{{ "i18n.shared.codelist-entry-detail.description.title" | translate }}</div>
							<div name="description" *ngIf="expandedElement && expandedElement.description">
								{{ expandedElement!.description | fallback: currentLanguage! }}
							</div>
						</div>
						<div [hidden]="!element.id">
							<div class="font-weight-600 element-detail-row">{{ "i18n.shared.codelist-entry-detail.annotation.title" | translate }}</div>
							<app-edit-table-annotation
								class="element-detail-row"
								(reloadEntriesEvent)="reloadEntries()"
								[id]="element.id"
								[annotations]="element.annotations"
							></app-edit-table-annotation>
							<br />
						</div>
					</div>
				</div>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
		<tr mat-row *matRowDef="let row; columns: columnsToDisplayWithExpand"></tr>
		<tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
	</table>

	<div class="table-info-box">
		<ob-alert type="info" *ngIf="!canCreateCodeValues()">{{ "i18n.shared.codelist.createcodelistfirst" | translate }}</ob-alert>
		<app-infobox-no-content [datasource]="dataSource.data" *ngIf="codelistId"></app-infobox-no-content>
	</div>
</div>
