import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {QualifiedAttribution} from '@bfs-sis/bfs-iop-admin-web-api-client';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {ObEExternalLinkIcon} from '@oblique/oblique';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
	selector: 'app-display-qualified-attribution',
	templateUrl: './display-qualified-attribution.component.html',
	styleUrls: []
})
export class DisplayQualifiedAttributionComponent implements OnInit, OnDestroy {
	@Input() resources: QualifiedAttribution[] | undefined;
	@Input() label = '';
	@Input() icon: ObEExternalLinkIcon = 'none';
	@Input() target = '_blank';
	@Input() rel = 'noopener noreferrer';

	currentLanguage: string = this.translate.currentLang;

	private readonly unsubscribe$ = new Subject();

	constructor(private readonly translate: TranslateService) {}

	ngOnInit(): void {
		this.translate.onLangChange.pipe(takeUntil(this.unsubscribe$)).subscribe((language: LangChangeEvent) => (this.currentLanguage = language.lang));
	}

	ngOnDestroy() {
		this.unsubscribe$.next(1);
		this.unsubscribe$.complete();
	}
}
