import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PublicationLevelView, RegistrationStatusView} from '@bfs-sis/bfs-iop-admin-web-api-client';
import {Observable, of} from 'rxjs';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {ActivatedRoute} from '@angular/router';
import {AppConfig} from '../../app.config';
import {IAppConfig} from '../../app.config.interface';

@Component({
	selector: 'app-status',
	templateUrl: './status.component.html',
	styleUrls: ['./status.component.scss']
})
export class StatusComponent {
	@Input() version: string | undefined;
	@Input() enableVersioning: boolean = false;
	@Input() disbaleNewVersion$: Observable<boolean> = of(true);
	@Input() registrationStatus: RegistrationStatusView | undefined;
	@Input() publicationLevel: PublicationLevelView | undefined;
	@Input() identifier: string | undefined;
	@Input() showWarning: boolean = false;
	@Input() warningText: string = '';
	@Input() showInfo: boolean = false;
	@Input() infoText: string = '';
	@Output() setRegistrationStatus: EventEmitter<string> = new EventEmitter();
	@Output() proposeRegistrationStatus: EventEmitter<string> = new EventEmitter();
	@Output() setPublicationLevel: EventEmitter<string> = new EventEmitter();
	@Output() proposePublicationLevel: EventEmitter<string> = new EventEmitter();
	currentLanguage: string;

	constructor(private readonly translate: TranslateService, private readonly route: ActivatedRoute) {
		this.currentLanguage = this.translate.currentLang;
		this.translate.onLangChange.subscribe((language: LangChangeEvent) => (this.currentLanguage = language.lang));
	}

	onSetRegistrationStatusClick(status: string) {
		this.setRegistrationStatus.emit(status);
	}

	onProposeRegistrationStatusClick(proposal: string) {
		this.proposeRegistrationStatus.emit(proposal);
	}

	onSetPublicationLevelClick(level: string) {
		this.setPublicationLevel.emit(level);
	}

	onProposePublicationLevelClick(proposal: string) {
		this.proposePublicationLevel.emit(proposal);
	}

	redirectToExternalResource(): string {
		const concepts = this.route?.parent?.snapshot?.url[0]?.path === 'concepts';
		const datasets = this.route?.parent?.snapshot?.url[1]?.path === 'datasets';
		const dataservices = this.route?.parent?.snapshot?.url[1]?.path === 'dataservices';
		const publicservices = this.route?.parent?.snapshot?.url[1]?.path === 'publicservices';

		const id = this.route.snapshot.params.id || this.route.snapshot.params.conceptId;
		const i14yPublicRoute = AppConfig.getConfig<IAppConfig>().I14Y_PUBLIC_ROUTE;

		const routes = {
			concepts: `${i14yPublicRoute}/${this.currentLanguage}/concepts/${id}/description`,
			dataservices: `${i14yPublicRoute}/${this.currentLanguage}/catalog/dataservices/${id}`,
			publicservices: `${i14yPublicRoute}/${this.currentLanguage}/catalog/publicservices/${this.identifier}/description`,
			datasets: `${i14yPublicRoute}/${this.currentLanguage}/catalog/datasets/${this.identifier}/description`
		};

		if (concepts) {
			return routes.concepts;
		}
		if (dataservices) {
			return routes.dataservices;
		}
		if (publicservices) {
			return routes.publicservices;
		}
		if (datasets) {
			return routes.datasets;
		}
		return '';
	}

	showDropdownButton(menuEntries: string[] | undefined): boolean {
		return menuEntries ? menuEntries.length > 0 : false;
	}
}
