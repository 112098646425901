<div [formGroup]="form">
	<h1 mat-dialog-title>{{ data.title }}</h1>
	<div mat-dialog-content>
		<div class="ob-grid">
			<div class="ob-span-4">
				<mat-form-field>
					<mat-label>{{ "i18n.shared.annotation.type.title" | translate }}</mat-label>
					<input matInput type="text" formControlName="type" />
				</mat-form-field>
			</div>
			<div class="ob-span-4">
				<mat-form-field>
					<mat-label>{{ "i18n.shared.annotation.title.title" | translate }}</mat-label>
					<input matInput type="text" formControlName="title" />
				</mat-form-field>
			</div>

			<div class="ob-span-4">
				<mat-form-field>
					<mat-label>{{ "i18n.shared.annotation.identifier.title" | translate }}</mat-label>
					<input matInput type="text" formControlName="identifier" />
				</mat-form-field>
			</div>
		</div>

		<mat-form-field formGroupName="text" *ngFor="let lang of data.contentLanguages">
			<mat-label>{{ "i18n.shared.annotation.text.title" | translate }} ({{ lang | uppercase }})</mat-label>
			<textarea formControlName="{{ lang }}" matInput></textarea>
		</mat-form-field>

		<mat-form-field>
			<mat-label>{{ "i18n.shared.annotation.uri.title" | translate }}</mat-label>
			<input matInput type="text" matInput formControlName="uri" />
		</mat-form-field>
	</div>

	<div mat-dialog-actions>
		<button type="button" mat-button obButton="tertiary" matTooltip=" {{ 'i18n.button.cancel.tooltip' | translate }}" mat-dialog-close>
			{{ "i18n.button.cancel" | translate }}
		</button>
		<button
			mat-button
			type="submit"
			obButton="primary"
			matTooltip=" {{ 'i18n.button.save.tooltip' | translate }}"
			(click)="onSubmit()"
			cdkFocusInitial
			[disabled]="form.pristine || form.invalid"
		>
			{{ "i18n.button.save" | translate }}
		</button>
	</div>
</div>
