export const NAV_PARAM_FROM = 'from';
export const NAV_PARAM_CONTENT_TYPE = 'contentType';
export const NAV_VALUE_EDIT = 'edit';
export const NAV_VALUE_CREATE = 'create';
export const NAV_VALUE_DETAIL = 'detail';
export const NAV_VALUE_DATAELEMENT = 'dataElement';
export const NAV_VALUE_OVERVIEW = 'overview';
export const NAV_VALUE_VERSION = 'newversion';
export const NAV_VALUE_CONTENT_CL = 'cl';
export const NAV_VALUE_CONTENT_HCL = 'hcl';

// Shared Dialog Button Keys
export const SHARED_DIALOG_OK_BUTTON_KEY = 'i18n.shared.dialoge.buttons.ok';
export const SHARED_DIALOG_CANCEL_BUTTON_KEY = 'i18n.shared.dialoge.buttons.cancel';
export const SHARED_DIALOG_CONFIRM_BUTTON_KEY = 'i18n.shared.dialoge.buttons.confirm';
export const SHARED_DIALOG_DISCARDCHANGES_BUTTON_KEY = 'i18n.shared.dialoge.buttons.discardchanges';
export const SHARED_DIALOG_SAVECHANGES_BUTTON_KEY = 'i18n.shared.dialoge.buttons.savechanges';
export const SHARED_DIALOG_CREATE_BUTTON_KEY = 'i18n.shared.dialoge.buttons.create';

export const DEFAULT_VERSION = '1.0.0';

export const URI_PATTERN = // eslint-disable-next-line max-len
	"(?:(https?|ftp):\\/\\/(?:[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*\\.[a-zA-Z]{2,}|(?:\\[[^\\]]+\\]))(?:\\:[0-9]+)?(?:\\/[^\\s?#]*)?(?:\\?[^#\\s]*)?(?:\\#[^\\s]*)?|ldap:\\/\\/(?:\\[[^\\]]+\\]|[^\\/\\s:?#]+)(?:\\:[0-9]+)?(?:\\/[^\\s?#]*)?((?:\\?[^#\\s]*)?(?:\\#[^\\s]*)?)|mailto:[^\\s?#]+|news:[^\\s?#]+|tel:[^\\s?#]+|telnet:\\/\\/(?:\\[[^\\]]+\\]|[^\\/\\s:?#]+)(?:\\:[0-9]+)?(?:\\/[^\\s?#]*)?(?:\\?[^#\\s]*)?(?:\\#[^\\s]*)?|urn:[a-zA-Z0-9][a-zA-Z0-9-]{1,31}:(?:[a-zA-Z0-9()+,\\-.:=@;$_!*'%/?#]+))";
