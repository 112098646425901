import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ObINavigationLink, ObMasterLayoutComponent} from '@oblique/oblique';
import {SessionService} from './services/session.service';
import {Observable, Subject, takeUntil} from 'rxjs';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {IAgent} from '@bfs-sis/bfs-iop-admin-web-api-client';
import {DataserviceInjectablesService} from './dataservices/services/dataservice-injectables.service';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

// eslint-disable-next-line no-shadow
enum BusinessRole {
	InteroperabilityService = 'Interoperability Service',
	LocalDataSteward = 'Local Data Steward',
	Submitter = 'Submitter',
	StewardshipOrganisationViewer = 'Stewardship Organisation Viewer',
	Unknown = 'Unknown'
}

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: []
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
	currentYear = new Date().getFullYear();
	isLoggedIn: Observable<boolean>;
	agents: IAgent[] = [];
	validatedBusinessRoles: string[] = [];
	businessRoles: number = 3;
	token: any;
	access_token!: string;
	currentLanguage: string;
	showMoreButton: boolean = true;

	readonly navigation: ObINavigationLink[] = [
		{
			url: 'home',
			label: 'i18n.navigation.home'
		},
		{
			url: 'catalog',
			label: 'i18n.navigation.catalog'
		},
		{
			url: 'concepts',
			label: 'i18n.navigation.concepts'
		}
	];

	@ViewChild(ObMasterLayoutComponent) private readonly masterLayout: ObMasterLayoutComponent | undefined;

	private readonly unsubscribe$ = new Subject();

	constructor(
		private readonly dataserviceInjectablesService: DataserviceInjectablesService,
		private readonly sessionService: SessionService,
		private readonly translate: TranslateService,
		private readonly titleService: Title,
		private readonly router: Router
	) {
		this.currentLanguage = this.translate.currentLang;
		this.translate.onLangChange.pipe(takeUntil(this.unsubscribe$)).subscribe((language: LangChangeEvent) => (this.currentLanguage = language.lang));
		this.isLoggedIn = this.sessionService.isAuthenticated$;
	}

	ngAfterViewInit(): void {
		this.translate.stream('i18n.title.page').subscribe(result => {
			this.titleService.setTitle(result);
		});

		this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe(() => this.masterLayout!.scrollTarget?.scrollTo({top: 0, behavior: 'smooth'}));
	}

	ngOnInit() {
		this.sessionService.getTokenPropertiesAsObservable().subscribe(x => {
			this.token = x;
			if (this.token) {
				this.validateBusinessRoles();
				this.generateAccessToken();
				this.getAgents();
			}
		});
	}

	ngOnDestroy() {
		this.unsubscribe$.next(1);
		this.unsubscribe$.complete();
	}

	getAgents(): void {
		this.dataserviceInjectablesService.agentClient.getUser().subscribe(response => {
			this.agents = response.result;
		});
	}

	generateAccessToken(): void {
		const accessToken = localStorage.getItem('access_token') ?? '';
		this.access_token = `Bearer ${accessToken}`;
	}

	validateBusinessRoles(): void {
		if (this.validatedBusinessRoles.length === 0 && this.token?.role) {
			if (this.token?.role.includes('BFS-i14y.dcat_registration_status_validation_all')) {
				this.validatedBusinessRoles.push(BusinessRole.InteroperabilityService);
			} else if (this.token?.role.includes('BFS-i14y.dcat_registration_status_validation_limited')) {
				this.validatedBusinessRoles.push(BusinessRole.LocalDataSteward);
			} else if (this.token?.role.includes('BFS-i14y.dcat_registration_status_proposal_basic')) {
				this.validatedBusinessRoles.push(BusinessRole.Submitter);
				// eslint-disable-next-line
			} else if (this.token?.role.includes('BFS-i14y.dcat_agent_read') && this.token?.role.includes('BFS-i14y.dcat_agent_create') === false) {
				this.validatedBusinessRoles.push(BusinessRole.StewardshipOrganisationViewer);
			} else {
				this.validatedBusinessRoles.push(BusinessRole.Unknown);
			}
		} else {
			// eslint-disable-next-line no-unused-expressions
			this.validatedBusinessRoles?.length > 0 ? null : this.validatedBusinessRoles.push(BusinessRole.Unknown);
		}
	}

	login() {
		this.sessionService.login();
	}

	logout() {
		this.sessionService.logout();
	}

	showAllBusinessRoles(): void {
		this.businessRoles = this.token?.role?.length ?? 10;
		this.toggleShowMoreButton();
	}

	toggleShowMoreButton() {
		this.showMoreButton = !this.showMoreButton;
	}
}
