import {Injectable} from '@angular/core';
import {AgentClient, SmsLinksClient, VocabularyClient} from '@bfs-sis/bfs-iop-admin-web-api-client';

@Injectable()
export class DatasetClientsService {
	constructor(
		private readonly _agentClient: AgentClient,
		private readonly _smsLinkController: SmsLinksClient,
		private readonly _vocabularyClient: VocabularyClient
	) {}

	get agentClient(): AgentClient {
		return this._agentClient;
	}

	get smsLinkController(): SmsLinksClient {
		return this._smsLinkController;
	}

	get vocabularyClient(): VocabularyClient {
		return this._vocabularyClient;
	}
}
