import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, QueryList, SimpleChanges, ViewChild, ViewChildren} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {CodeListEntryDetail, CodelistEntryInputClient, MultiLanguage, SwaggerResponse} from '@bfs-sis/bfs-iop-admin-web-api-client';
import {SelectionModel} from '@angular/cdk/collections';
import {ObNotificationService} from '@oblique/oblique';
import {HttpStatusCode} from '@angular/common/http';
import {Languages} from 'src/app/shared/ApplicationLanguage.enum';
import {MultiLanguageMapper} from '../mappers/multilanguagemapper';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig} from '@angular/material/legacy-dialog';
import {ModalDialogCodeListComponent} from './modal-dialog/modal-dialog.component';
import {DialogComponent, DialogType} from '../dialog/dialog.component';
import {SHARED_DIALOG_CANCEL_BUTTON_KEY, SHARED_DIALOG_CONFIRM_BUTTON_KEY} from '../../app-constants';
import {TranslateService} from '@ngx-translate/core';

@Component({
	selector: 'app-edit-table-codelist',
	templateUrl: './edit-table-codelist.component.html',
	styleUrls: ['./edit-table-codelist.component.scss'],
	animations: [
		trigger('detailExpand', [
			state('collapsed', style({height: '0px', minHeight: '0'})),
			state(
				'expanded',
				style({
					height: '*',
					minHeight: ''
				})
			),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
		])
	]
})
export class EditTableCodelistComponent implements AfterViewInit, OnChanges {
	@ViewChild(MatSort) sort!: MatSort;
	@ViewChildren('focusInputField') focusInputFields!: QueryList<ElementRef>;
	@Input() codelistId: string | undefined;
	@Input() isNewCodeList: boolean = false;
	@Input() showAllLanguages: boolean = false;
	@Input() currentLanguage: string | undefined;
	@Input() controlName!: string;
	@Output() updateCodeListEntries: EventEmitter<void> = new EventEmitter();
	@Input() dto!: CodeListEntryDetail[];
	public dataSource = new MatTableDataSource<CodeListEntryDetail>([]);

	contentLanguages: readonly string[] = Languages.ContentLanguagesRm;

	COLUMN_VALUE = 'value';
	COLUMN_PARENTCODE = 'parentCode';
	COLUMN_SELECT = 'select';
	COLUMN_ACTIONS = 'actions';

	columnsToDisplayWithExpand = [this.COLUMN_SELECT, this.COLUMN_VALUE, this.COLUMN_PARENTCODE, ...this.contentLanguages, 'expand', this.COLUMN_ACTIONS];
	expandedElement: CodeListEntryDetail | null | undefined;
	isExpanded = false;

	private selection = new SelectionModel<CodeListEntryDetail>(true, []);

	constructor(
		private readonly codelistEntryInputClient: CodelistEntryInputClient,
		public dialog: MatDialog,
		readonly translate: TranslateService,
		private readonly notification: ObNotificationService
	) {}

	ngAfterViewInit(): void {
		this.dataSource.sort = this.sort;
	}

	onChangeDescription(event: any, lang: string | undefined) {
		const txt = event.target.value;

		if (this.expandedElement) {
			if (!this.expandedElement.description) {
				this.expandedElement.description = new MultiLanguage();
			}
			this.expandedElement.description[lang as keyof MultiLanguage] = txt;
		}
	}

	onReadDescription(lang: string) {
		if (this.expandedElement && this.expandedElement.description) {
			return this.expandedElement.description[lang as keyof MultiLanguage] ?? '';
		}
		return '';
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.dto) {
			let change = changes.dto;
			if (change.currentValue !== undefined) {
				this.dataSource = new MatTableDataSource<CodeListEntryDetail>(this.dto);
				this.dataSource.filter = '';
			}
		}
	}

	onMasterToggle(): void {
		if (this.isAllSelected()) {
			this.selection.clear();
		} else {
			this.dataSource.data.forEach(row => this.selection.select(row));
		}
	}

	removeRow(index: number): void {
		const dialogRef = this.dialog.open(DialogComponent, {
			data: {
				showHeader: true,
				enableSave: false,
				headerText: this.translate.instant('i18n.page.edittable.deletedialog.headertext'),
				bodyText: this.translate.instant('i18n.page.edittable.deletedialog.bodytext'),
				dialogType: DialogType.confirm,
				okButtonText: '',
				cancelButtonText: this.translate.instant(SHARED_DIALOG_CANCEL_BUTTON_KEY),
				confirmButtonText: this.translate.instant(SHARED_DIALOG_CONFIRM_BUTTON_KEY),
				discardChangesButtonText: '',
				saveChangesButtonText: ''
			}
		});

		const dialogConfirm = dialogRef.componentInstance.confirm.subscribe(() => {
			const row = this.dataSource.data.splice(index, 1);
			if (row) {
				this.deleteRequest(row[0]).then(success => {
					if (success) {
						this.showSuccessNotification();
						this.getCodeListEntries();
						this.refreshDatabinding();
					} else {
						this.showErrorNotification();
					}
				});
			}
		});

		dialogRef.afterClosed().subscribe(() => {
			dialogConfirm.unsubscribe();
		});
	}

	onRemoveSelectedRows(): void {
		const dialogRef = this.dialog.open(DialogComponent, {
			data: {
				showHeader: true,
				enableSave: false,
				headerText: this.translate.instant('i18n.page.edittable.deletedialog.headertext'),
				bodyText: this.translate.instant('i18n.page.edittable.deletedialog.bodytext'),
				dialogType: DialogType.confirm,
				okButtonText: '',
				cancelButtonText: this.translate.instant(SHARED_DIALOG_CANCEL_BUTTON_KEY),
				confirmButtonText: this.translate.instant(SHARED_DIALOG_CONFIRM_BUTTON_KEY),
				discardChangesButtonText: '',
				saveChangesButtonText: ''
			}
		});
		const dialogConfirm = dialogRef.componentInstance.confirm.subscribe(() => {
			const promises = this.selection.selected.map((item: CodeListEntryDetail): Promise<boolean> => {
				let index = this.dataSource.data.findIndex((d: CodeListEntryDetail) => d === item);
				const row = this.dataSource.data.splice(index, 1);
				if (row) {
					return this.deleteRequest(row[0]);
				}
				return Promise.resolve(false);
			});

			Promise.all(promises).then(results => {
				if (results.every(success => success)) {
					this.showSuccessNotification();
					this.getCodeListEntries();
					this.refreshDatabinding();
				} else {
					this.showErrorNotification();
				}
			});

			this.selection = new SelectionModel<CodeListEntryDetail>(true, []);
		});
		dialogRef.afterClosed().subscribe(() => {
			dialogConfirm.unsubscribe();
		});
	}

	onExpandRow(row: CodeListEntryDetail) {
		if (this.expandedElement !== row && this.isExpanded) {
			this.isExpanded = !this.isExpanded;
		}
		this.isExpanded = !this.isExpanded;
		if (this.isExpanded) {
			this.expandedElement = row;
		}
	}

	addRow(): void {
		this.showDialog(this.newEntry());
	}

	editRow(row: CodeListEntryDetail): void {
		row.description = row.description ?? MultiLanguageMapper.fixEmptyValues(new MultiLanguage());

		this.showDialog(row);
	}

	onFindIndex(row: CodeListEntryDetail): number {
		return this.dataSource.data.findIndex(x => x === row);
	}

	displayParentCode(code: CodeListEntryDetail) {
		return code?.value ?? '';
	}

	canAdd(): boolean {
		return !this.hasSelectedItems();
	}

	canCreateCodeValues(): boolean {
		return this.isNewCodeList;
	}

	hasRows(): boolean {
		return this.dataSource?.data?.length > 0;
	}

	hasSelectedRows(): boolean {
		return this.selection.hasValue();
	}

	isAllSelected(): boolean {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource.data.length;
		return numSelected === numRows;
	}

	isRowSelected(row: CodeListEntryDetail): boolean {
		return this.selection.isSelected(row);
	}

	reloadEntries() {
		this.getCodeListEntries();
	}

	onToggleSelection(row: CodeListEntryDetail): void {
		this.selection.toggle(row);
	}

	private updateAfterSave(success: boolean) {
		if (success) {
			this.showSuccessNotification();
			this.getCodeListEntries();
			this.refreshDatabinding();
		} else {
			this.showErrorNotification();
		}
	}

	private hasSelectedItems(): boolean {
		return !this.selection.isEmpty();
	}

	private newEntry(): CodeListEntryDetail {
		return new CodeListEntryDetail({
			codelistId: this.codelistId,
			value: undefined,
			parentCode: undefined,
			name: MultiLanguageMapper.fixEmptyValues(new MultiLanguage()),
			description: MultiLanguageMapper.fixEmptyValues(new MultiLanguage())
		});
	}

	private getCodeListEntries(): void {
		this.updateCodeListEntries.emit();
	}

	private refreshDatabinding(): void {
		this.dataSource.filter = '';
	}

	private validateHttpStatus(response: SwaggerResponse<void>, resolve: (value: boolean | PromiseLike<boolean>) => void, code: HttpStatusCode) {
		if (response.status === code) {
			resolve(true);
		} else {
			resolve(false);
		}
	}

	private putRequest(codelistEntry: CodeListEntryDetail): Promise<boolean> {
		return new Promise<boolean>(resolve => {
			this.codelistEntryInputClient.putByBody(codelistEntry).subscribe(response => {
				this.validateHttpStatus(response, resolve, HttpStatusCode.NoContent);
			});
		});
	}

	private deleteRequest(codelistEntry: CodeListEntryDetail): Promise<boolean> {
		return new Promise<boolean>(resolve => {
			if (codelistEntry.id) {
				this.codelistEntryInputClient.deleteById(codelistEntry.id).subscribe(response => {
					this.validateHttpStatus(response, resolve, HttpStatusCode.NoContent);
				});
			}
		});
	}

	private postRequest(codelistEntry: CodeListEntryDetail): Promise<boolean> {
		return new Promise<boolean>(resolve => {
			this.codelistEntryInputClient.postByBody(codelistEntry).subscribe(response => {
				this.validateHttpStatus(response, resolve, HttpStatusCode.Created);
			});
		});
	}

	private showDialog(entry: CodeListEntryDetail) {
		const dialogConfig = new MatDialogConfig();

		this.updateDialogConfig(entry, dialogConfig);

		const dialogRef = this.dialog.open(ModalDialogCodeListComponent, dialogConfig);

		dialogRef.afterClosed().subscribe(data => {
			if (data) {
				if (data.codeListEntry.id) {
					this.putRequest(data.codeListEntry).then(success => {
						this.updateAfterSave(success);
					});
				} else {
					this.postRequest(data.codeListEntry).then(success => {
						this.updateAfterSave(success);
					});
				}
			}
		});
	}

	private showSuccessNotification(): void {
		this.notification.success('i18n.app.notification.save.succeeded');
	}

	private showErrorNotification(): void {
		this.notification.error('i18n.app.notification.save.error');
	}

	private updateDialogConfig(entry: CodeListEntryDetail, dialogConfig: MatDialogConfig<any>) {
		dialogConfig.data = {contentLanguages: this.contentLanguages, codeListEntry: entry};
		dialogConfig.width = '70%';
		dialogConfig.maxWidth = '1200px';
		dialogConfig.minWidth = '600px';
		dialogConfig.disableClose = false;
		dialogConfig.autoFocus = true;
	}
}
