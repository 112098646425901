import {DatasetClient, PublicationLevelView, RegistrationStatusView} from '@bfs-sis/bfs-iop-admin-web-api-client';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {ReplaySubject, Subject} from 'rxjs';

@Injectable()
export class DatasetStatusService {
	readonly level$: Observable<PublicationLevelView>;
	readonly status$: Observable<RegistrationStatusView>;
	private last: string | undefined;

	private readonly level: Subject<PublicationLevelView> = new ReplaySubject<PublicationLevelView>(1);
	private readonly status: Subject<RegistrationStatusView> = new ReplaySubject<RegistrationStatusView>(1);

	constructor(private readonly datasetClient: DatasetClient) {
		this.level$ = this.level.asObservable();
		this.status$ = this.status.asObservable();
	}

	load(id: string, force: boolean = false) {
		if (force || !this.last || this.last !== id) {
			this.datasetClient.getPublicationLevelById(id).subscribe(response => this.level.next(response.result));
			this.datasetClient.getRegistrationStatusById(id).subscribe(response => this.status.next(response.result));
			this.last = id;
		}
	}
}
