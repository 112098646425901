import {Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, QueryList, SimpleChanges, ViewChild, ViewChildren} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {
	ChannelInputClient,
	ChannelSummary,
	CodeListEntryDetail,
	IPublicOrganisation,
	MultiLanguage,
	PublicOrganisation,
	PublicOrganisationClient
} from '@bfs-sis/bfs-iop-admin-web-api-client';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {SelectionModel} from '@angular/cdk/collections';
import {Observable, Subject} from 'rxjs';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {ObNotificationService} from '@oblique/oblique';
import {map, startWith, takeUntil} from 'rxjs/operators';
import {HttpStatusCode} from '@angular/common/http';
import {UntypedFormControl} from '@angular/forms';
import {MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent} from '@angular/material/legacy-autocomplete';

@Component({
	selector: 'app-edit-table-channel-link',
	templateUrl: './edit-table-channel-link.component.html',
	styleUrls: ['./edit-table-channel-link.component.scss']
})
export class EditTableChannelLinkComponent implements OnInit, OnChanges, OnDestroy {
	/* TODO: Refactoring! */
	@ViewChild(MatSort, {static: false}) sort!: MatSort;
	@ViewChildren('focusInputFi-eld') focusInputFields!: QueryList<ElementRef>;
	@Input() channelInputId: string | undefined;
	@Input() editMode: boolean = false;

	currentLanguage: string;
	ownedBy!: PublicOrganisation;
	dto: PublicOrganisation[] = [];
	dataSource = new MatTableDataSource<PublicOrganisation>(this.dto);
	publicOrganisationFormControl: UntypedFormControl = new UntypedFormControl();
	filteredPublicOrganisations: PublicOrganisation[] = [];
	publicOrganisations: PublicOrganisation[] = [];
	filteredPublicOrganisations$!: Observable<IPublicOrganisation[]>;
	COLUMN_SELECT = 'select';
	COLUMN_NAME = 'name';
	COLUMN_ACTIONS = 'actions';
	displayedColumns: string[] = [this.COLUMN_SELECT, this.COLUMN_NAME, this.COLUMN_ACTIONS];

	private isEditing = false;
	private isAddMode = false;
	private rowIndex: number | undefined;
	private selection = new SelectionModel<PublicOrganisation>(true, []);
	private readonly unsubscribe$ = new Subject();

	constructor(
		public readonly translate: TranslateService,
		private readonly channelInputClient: ChannelInputClient,
		private readonly notification: ObNotificationService,
		private readonly publicOrganisationClient: PublicOrganisationClient
	) {
		this.publicOrganisationClient.get().subscribe(data => {
			this.publicOrganisations = data.result;
		});
		this.currentLanguage = this.translate.currentLang;
	}

	ngOnInit(): void {
		this.dataSource.sort = this.sort;
		this.translate.onLangChange.pipe(takeUntil(this.unsubscribe$)).subscribe((language: LangChangeEvent) => {
			this.currentLanguage = language.lang;
		});

		this.filteredPublicOrganisations$ = this.publicOrganisationFormControl.valueChanges.pipe(
			startWith(''),
			map(value => {
				const publicOrganisation = typeof value === 'string' ? value : value?.name.currentLanguage;
				return publicOrganisation ? this.filterPublicOrganisations(publicOrganisation as string) : this.filteredPublicOrganisations.slice();
			})
		);
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.channelInputId) {
			let change = changes.channelInputId;
			if (change.currentValue !== undefined) {
				this.getDataSource();
				this.dataSource = new MatTableDataSource<CodeListEntryDetail>(this.dto);
			}
		}
	}

	filterPublicOrganisations(name: string): IPublicOrganisation[] {
		const filterTerm = name.toLowerCase();
		// @ts-ignore
		return this.filteredPublicOrganisations.filter((option: IPublicOrganisation) =>
			// @ts-ignore
			option.name[this.currentLanguage].toLowerCase().includes(filterTerm)
		);
	}

	ngOnDestroy() {
		this.unsubscribe$.next(1);
		this.unsubscribe$.complete();
	}

	canAdd(): boolean {
		return !this.isEditing && !this.hasSelectedItems();
	}

	canEdit(): boolean {
		return !this.isEditing;
	}

	canRemove(): boolean {
		return !this.isEditing;
	}

	canSave(rowIndex: number): boolean {
		if (this.isRowEditMode(rowIndex)) {
			if (this.dataSource.data[rowIndex].name) {
				return true;
			}
		}
		return false;
	}

	canSelect(): boolean {
		return !this.isEditing;
	}

	hasRows(): boolean {
		return this.dataSource?.data?.length > 0;
	}

	hasSelectedRows(): boolean {
		return this.selection.hasValue();
	}

	isAllSelected(): boolean {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource.data.length;

		return numSelected === numRows;
	}

	isEditMode(): boolean {
		return this.isEditing;
	}

	isRowEditMode(index: number): boolean {
		return this.isEditing && this.rowIndex === index;
	}

	isRowSelected(row: PublicOrganisation): boolean {
		return this.selection.isSelected(row);
	}

	reloadEntries(): void {
		this.getDataSource();
	}

	onAddRow(): void {
		let numRows = this.dataSource.data.push(this.createEmptyDto());
		this.filteredPublicOrganisations = this.getDifferenceBetweenTwoArrays(this.publicOrganisations, this.dataSource.data);

		this.publicOrganisationFormControl.setValue('');
		this.isAddMode = true;
		this.UpdateIsEditing(true);
		this.rowIndex = numRows - 1;
		this.refreshDatabinding();
		this.setFocus();
	}

	onDiscard() {
		this.isAddMode = false;
		this.UpdateIsEditing(false);
		this.dataSource.data.splice(this.rowIndex!, 1);
		this.publicOrganisationFormControl.setValue('');

		this.rowIndex! -= 1;
		this.refreshDatabinding();
		this.setFocus();
	}

	onMasterToggle(): void {
		if (this.isAllSelected()) {
			this.selection.clear();
		} else {
			this.dataSource.data.forEach(row => this.selection.select(row));
		}
	}

	onRemoveRow(index: number, row?: PublicOrganisation): void {
		this.dataSource.data.splice(index, 1);
		if (!this.isAddMode) {
			this.deleteRequest(row as PublicOrganisation).then(success => {
				if (success) {
					this.getDataSource();
					this.refreshDatabinding();
				} else {
					this.showErrorNotification();
				}
			});
		}
	}

	onRemoveSelectedRows(): void {
		this.selection.selected.forEach(item => {
			let index: number = this.dataSource.data.findIndex((d: PublicOrganisation) => d === item);
			let publicOrganisation: PublicOrganisation | undefined = this.dataSource.data.find((d: PublicOrganisation) => d === item);
			this.onRemoveRow(index, publicOrganisation);
		});
		this.selection = new SelectionModel<PublicOrganisation>(true, []);
	}

	onSave() {
		this.isAddMode = false;
		this.UpdateIsEditing(false);

		if (this.ownedBy.id && this.channelInputId) {
			this.channelInputClient.putOwnedByByIdAndOwnedById(this.channelInputId, this.ownedBy.id).subscribe(_ => {
				this.showSuccessNotification();
				this.getDataSource();
				this.refreshDatabinding();
				this.setFocus();
			});
		}
	}

	onToggleSelection(row: PublicOrganisation): void {
		this.selection.toggle(row);
	}

	onSelectionChanged(event: MatAutocompleteSelectedEvent): void {
		this.ownedBy = event.option.value;
	}

	displayValue = (displayPublicOrganisation: any) => {
		return displayPublicOrganisation && displayPublicOrganisation.name[this.currentLanguage] ? displayPublicOrganisation.name[this.currentLanguage] : '';
	};

	private createEmptyDto(): PublicOrganisation {
		return new PublicOrganisation({
			id: undefined,
			spatial: undefined,
			name: new MultiLanguage({
				de: undefined,
				en: undefined,
				fr: undefined,
				it: undefined
			}),
			prefLabel: new MultiLanguage({
				de: undefined,
				en: undefined,
				fr: undefined,
				it: undefined
			})
		});
	}

	private deleteRequest(publicOrganisationEntry: PublicOrganisation): Promise<boolean> {
		return new Promise<boolean>(resolve => {
			if (this.channelInputId && publicOrganisationEntry.id) {
				this.channelInputClient.deleteOwnedByByIdAndOwnedById(this.channelInputId, publicOrganisationEntry.id).subscribe(response => {
					if (response.status === HttpStatusCode.NoContent) {
						resolve(true);
					} else {
						resolve(false);
					}
				});
			}
		});
	}

	private getDataSource(): void {
		if (this.channelInputId) {
			this.channelInputClient.getOwnedByById(this.channelInputId).subscribe(response => {
				this.dto = response.result ?? [];
				this.dataSource = new MatTableDataSource<ChannelSummary>(this.dto);
			});
		}
	}

	private hasSelectedItems(): boolean {
		return !this.selection.isEmpty();
	}

	private refreshDatabinding(): void {
		this.dataSource.filter = '';
	}

	private showSuccessNotification(): void {
		this.notification.success('i18n.app.notification.save.succeeded');
	}

	private showErrorNotification(): void {
		this.notification.error('i18n.app.notification.save.error');
	}

	private setFocus(): void {
		setTimeout(() => {
			this.focusInputFields.get(this.rowIndex!)?.nativeElement.focus();
		}, 100);
	}

	private UpdateIsEditing(value: boolean) {
		this.isEditing = value;
	}

	private getDifferenceBetweenTwoArrays(array1: PublicOrganisation[], array2: PublicOrganisation[]): PublicOrganisation[] {
		return array1.filter(object1 => {
			return !array2.some(object2 => {
				return object1.id === object2.id;
			});
		});
	}
}
