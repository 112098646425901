import {Injectable} from '@angular/core';
import {DatasetStatusService} from './dataset-status.service';

@Injectable()
export class DatasetServicesService {
	constructor(private readonly _datasetStatusService: DatasetStatusService) {}

	get datasetStatusService(): DatasetStatusService {
		return this._datasetStatusService;
	}
}
