import {Component} from '@angular/core';
import {Agent, AgentClient, CatalogClient, ConceptSummaryClient, FilterCountResultItem} from '@bfs-sis/bfs-iop-admin-web-api-client';
import {SessionService} from '../../services/session.service';
import {first} from 'rxjs/operators';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
	public publishersIds!: string[];
	public publishersIdendifier!: string[];

	public catalogAgencyData: FilterCountResultItem[] | undefined;
	public catalogAgencyPenndingLevels: FilterCountResultItem[] | undefined;
	public catalogAgencyPenndingStatuses: FilterCountResultItem[] | undefined;
	public catalogUserPenndingLevels: FilterCountResultItem[] | undefined;
	public catalogUserPenndingStatuses: FilterCountResultItem[] | undefined;

	public conceptAgencyData: FilterCountResultItem[] | undefined;
	public conceptAgencyPenndingLevels: FilterCountResultItem[] | undefined;
	public conceptAgencyPenndingStatuses: FilterCountResultItem[] | undefined;
	public conceptUserPenndingLevels: FilterCountResultItem[] | undefined;
	public conceptUserPenndingStatuses: FilterCountResultItem[] | undefined;

	public isvalidationAll: boolean = false;
	public isvalidationLimited: boolean = false;

	constructor(
		private readonly agentClient: AgentClient,
		private readonly catalogClient: CatalogClient,
		private readonly conceptSummaryClient: ConceptSummaryClient,
		private readonly sessionService: SessionService
	) {
		this.agentClient.getUser().subscribe((agent: any) => {
			this.publishersIds = agent.result.map((value: Agent) => value.id);
			this.publishersIdendifier = agent.result.map((value: Agent) => value.identifier);
			this.getData();
			this.getFilteredData();
		});

		this.sessionService.dcatRoles$.pipe(first()).subscribe(roles => {
			this.isvalidationAll = roles.includes('registration_status_validation_all');
			this.isvalidationLimited = roles.includes('registration_status_validation_limited');
		});
	}

	getData() {
		this.catalogClient // eslint-disable-next-line max-len
			.getSearchcountByQueryAndAccessRightsAndBusinessEventsAndFormatsAndLevelsAndLevelProposalsAndLifeEventsAndPublishersAndStatusesAndStatusProposalsAndThemesAndTypes(
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				this.publishersIds,
				undefined,
				undefined,
				undefined,
				undefined
			)
			.subscribe(response => {
				this.catalogAgencyData = response.result.types ?? undefined;
				this.catalogAgencyPenndingLevels = response.result.publicationLevelProposals?.filter(x => x.count && x.count > 0) ?? undefined;
				this.catalogAgencyPenndingStatuses = response.result.registrationStatusProposals?.filter(x => x.count && x.count > 0) ?? undefined;

				if (this.isvalidationAll) {
					this.catalogUserPenndingLevels = response.result.publicationLevelProposals?.filter(x => x.count && x.count > 0) ?? undefined;
				}
			});

		this.conceptSummaryClient
			.getSearchcountBySearchAndPublishersAndThemeCodesAndLevelsAndLevelProposalsAndStatusesAndStatusProposals(
				undefined,
				this.publishersIdendifier,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined
			)
			.subscribe(response => {
				this.conceptAgencyData = response.result.types ?? undefined;
				this.conceptAgencyPenndingLevels = response.result.publicationLevelProposals?.filter(x => x.count && x.count > 0) ?? undefined;
				this.conceptAgencyPenndingStatuses = response.result.registrationStatusProposals?.filter(x => x.count && x.count > 0) ?? undefined;

				if (this.isvalidationAll) {
					this.conceptUserPenndingLevels = response.result.publicationLevelProposals?.filter(x => x.count && x.count > 0) ?? undefined;
				}
			});
	}

	getFilteredData() {
		if (this.isvalidationAll || this.isvalidationLimited) {
			this.catalogClient // eslint-disable-next-line max-len
				.getSearchcountByQueryAndAccessRightsAndBusinessEventsAndFormatsAndLevelsAndLevelProposalsAndLifeEventsAndPublishersAndStatusesAndStatusProposalsAndThemesAndTypes(
					undefined,
					undefined,
					undefined,
					undefined,
					this.getLevelFilter(),
					undefined,
					undefined,
					this.publishersIds,
					undefined,
					undefined,
					undefined,
					undefined
				)
				.subscribe(response => {
					this.catalogUserPenndingStatuses = response.result.registrationStatusProposals?.filter(x => x.count && x.count > 0) ?? undefined;
				});

			this.conceptSummaryClient
				.getSearchcountBySearchAndPublishersAndThemeCodesAndLevelsAndLevelProposalsAndStatusesAndStatusProposals(
					undefined,
					this.publishersIdendifier,
					undefined,
					this.getLevelFilter(),
					undefined,
					undefined,
					undefined
				)
				.subscribe(response => {
					this.conceptUserPenndingStatuses = response.result.registrationStatusProposals?.filter(x => x.count && x.count > 0) ?? undefined;
				});
		}
	}

	getLevelFilter(): string[] | undefined {
		if (this.isvalidationLimited) {
			return ['Unit'];
		}

		if (this.isvalidationAll) {
			return ['I14Y Public'];
		}
		return undefined;
	}

	getRegistrationStatusKey(code: string): string {
		return 'i18n.shared.status.registrationstatus.' + code.replace(' ', '').toLowerCase();
	}

	getPublicationLevelKey(code: string): string {
		return 'i18n.shared.status.publicationlevel.' + code.replace(' ', '').toLowerCase();
	}
}
