import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {map, Observable} from 'rxjs';
import {SessionService} from '../services/session.service';

@Injectable({
	providedIn: 'root'
})
class AuthGuardSerivce {
	constructor(private readonly sessionService: SessionService, private readonly router: Router) {}

	canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.isAuthenticatedElseLogin(state);
	}
	canActivateChild(
		_childRoute: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.isAuthenticatedElseLogin(state);
	}

	isAuthenticatedElseLogin(state: RouterStateSnapshot): Observable<boolean | UrlTree> | boolean {
		if (this.sessionService.isAuthenticated()) {
			return this.sessionService.dcatRoles$.pipe(map(roles => (roles.includes('agent_read') ? true : this.router.parseUrl('/unauthorized'))));
		}
		const redirectUrl = state.url;
		localStorage.setItem('redirectUrl', redirectUrl);
		this.sessionService.login();
		return false;
	}
}

export const AuthGuard: CanActivateFn = (
	next: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
	return inject(AuthGuardSerivce).canActivate(next, state);
};

export const AuthChildGuard: CanActivateChildFn = (
	childRoute: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
	return inject(AuthGuardSerivce).canActivateChild(childRoute, state);
};
