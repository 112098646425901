import {Pipe, PipeTransform} from '@angular/core';
import {IMultiLanguage} from '@bfs-sis/bfs-iop-admin-web-api-client';
import {FormatFunctions} from '../format-functions';

/*
 * Display the fallback language if the current language is not available
 * Usage:
 *   value | fallbackarraytostring:language:separator:defaultValue
 * Example:
 *   {{ ml | fallbackarraytostring:"fr":", ": "-" }}
 */
@Pipe({name: 'fallbackarraytostring'})
export class FallbackArrayToStringPipe implements PipeTransform {
	transform(source: IMultiLanguage[] | undefined, language: string, separator: string = ', ', defaultValue: string = ''): string | null {
		return FormatFunctions.convertArrayToString(
			source
				?.map(ml => {
					if (ml) {
						return [ml[language as keyof IMultiLanguage], ml.de, ml.fr, ml.it, ml.en].find(x => x || x === '') ?? '';
					}
					return '';
				})
				.filter(x => x !== ''),
			separator,
			defaultValue
		);
	}
}
