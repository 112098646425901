import {animate, state, style, transition, trigger} from '@angular/animations';
import {Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {CodeListEntryDetail, ICodeListEntryDetail, MultiLanguage} from '@bfs-sis/bfs-iop-admin-web-api-client';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {Subject, takeUntil} from 'rxjs';
import {SortableListViewComponent} from 'src/app/shared/sortable-list-view/sortable-list-view.component';
import {IFilterModel} from '../../datasets/data-element/data-element-edit/data-element-edit-form/filter-input/filter-input.component';

type SortableKeys = 'value' | 'parentCode' | 'name';

@Component({
	selector: 'app-codevalue-table',
	templateUrl: './codevalue-table.component.html',
	styleUrls: ['./codevalue-table.component.scss'],
	animations: [
		trigger('detailExpand', [
			state('collapsed', style({height: '0px', minHeight: '0'})),
			state(
				'expanded',
				style({
					height: '*',
					minHeight: ''
				})
			),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
		])
	]
})
export class CodevalueTableComponent extends SortableListViewComponent<ICodeListEntryDetail, SortableKeys> implements OnChanges, OnDestroy {
	@Input() dto!: CodeListEntryDetail[];
	@Input() showHeader: boolean = true;
	@Input() setFilter: IFilterModel | undefined;
	dataSource = new MatTableDataSource<ICodeListEntryDetail>([]);

	COLUMN_VALUE = 'code';
	COLUMN_PARENTCODE = 'parentCode';
	COLUMN_NAME = 'name';
	COLUMN_ADDITIONALINFO = 'additionalInfo';

	currentLanguage: string;

	displayedColumns = [this.COLUMN_VALUE, this.COLUMN_NAME, this.COLUMN_PARENTCODE, this.COLUMN_ADDITIONALINFO];
	expandedElement: ICodeListEntryDetail | null | undefined;

	private readonly unsubscribe$ = new Subject();

	constructor(readonly translate: TranslateService) {
		super(translate);
		this.currentLanguage = this.translate.currentLang;
		this.translate.onLangChange.pipe(takeUntil(this.unsubscribe$)).subscribe((language: LangChangeEvent) => (this.currentLanguage = language.lang));
	}

	ngOnDestroy() {
		this.unsubscribe$.next(1);
		this.unsubscribe$.complete();
	}

	ngOnChanges(changes: SimpleChanges) {
		const change = changes.dto;
		if (change.currentValue !== undefined) {
			this.data = this.dto;
		}
	}

	hasAdditionalInfo(entry: ICodeListEntryDetail): boolean {
		return (
			((entry && entry.description && Object.keys(entry.description).some(x => entry.description![x as keyof MultiLanguage])) ||
				(entry && entry.annotations && entry.annotations.length > 0)) ??
			false
		);
	}
}
