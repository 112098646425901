import {Injectable} from '@angular/core';
import {CodelistIdentifierValidator} from 'src/app/shared/validators/codelist-identifier.validator';
import {DatasetIdentifierValidator} from 'src/app/shared/validators/dataset-identifier.validator';
import {NewVersionDatasetIdentifierValidator} from 'src/app/shared/validators/new-Version-dataset-identifier.validator';
import {StructureIdentifierValidator} from 'src/app/shared/validators/structure-identifier.validator';

@Injectable()
export class DatasetValidatorsService {
	constructor(
		private readonly _codelistIdentifierValidator: CodelistIdentifierValidator,
		private readonly _datasetIdentifierValidator: DatasetIdentifierValidator,
		private readonly _newVersionDatasetIdentifierValidator: NewVersionDatasetIdentifierValidator,
		private readonly _structureIdentifierValidator: StructureIdentifierValidator
	) {}

	get codelistIdentifierValidator(): CodelistIdentifierValidator {
		return this._codelistIdentifierValidator;
	}

	get datasetIdentifierValidator(): DatasetIdentifierValidator {
		return this._datasetIdentifierValidator;
	}

	get newVersionDatasetIdentifierValidator(): NewVersionDatasetIdentifierValidator {
		return this._newVersionDatasetIdentifierValidator;
	}

	get structureIdentifierValidator(): StructureIdentifierValidator {
		return this._structureIdentifierValidator;
	}
}
