<div [formGroup]="form">
	<h2 mat-dialog-title>{{ "i18n.shared.codelist.modal.titel" | translate }}</h2>
	<div mat-dialog-content>
		<mat-form-field>
			<mat-label>{{ "i18n.shared.codelist.value" | translate }}</mat-label>
			<input formControlName="value" matInput #focusInputField />
			<mat-error *ngIf="form?.get('value')?.errors?.whitespace">{{ "i18n.shared.error.whitespace.value" | translate }}</mat-error>
			<mat-error *ngIf="form?.get('value')?.errors?.required">
				{{ "i18n.shared.codelist.value.error" | translate }}
			</mat-error>
		</mat-form-field>

		<mat-form-field>
			<mat-label>{{ "i18n.shared.codelist.parentcode" | translate }}</mat-label>
			<input
				#parentCodeInput
				formControlName="parentCode"
				matInput
				(input)="onParentCodeInputChanged(parentCodeInput.value)"
				[matAutocomplete]="parentCodeAutocomplete"
			/>
			<mat-autocomplete #parentCodeAutocomplete="matAutocomplete" autoActiveFirstOption="true">
				<mat-option
					*ngFor="let parentCodeValue of searchCodeListEntries$ | async"
					[value]="parentCodeValue"
					(onSelectionChange)="onParentCodeSelection(parentCodeValue)"
				>
					{{ parentCodeValue }}
				</mat-option>
			</mat-autocomplete>
		</mat-form-field>

		<h3 mat-dialog-title>{{ "i18n.shared.codelist.modal.titel.name" | translate }}</h3>

		<mat-form-field formGroupName="name" *ngFor="let lang of data.contentLanguages">
			<mat-label>{{ "i18n.shared.codelist.codename." + lang | translate }}</mat-label>
			<input id="codelist-codename-{{ lang }}" formControlName="{{ lang }}" matInput />
		</mat-form-field>

		<h3 mat-dialog-title>{{ "i18n.shared.codelist.modal.titel.description" | translate }}</h3>

		<mat-form-field formGroupName="description" *ngFor="let lang of data.contentLanguages">
			<mat-label>{{ "i18n.page.datasets.content.edit.description" | translate }} ({{ lang | uppercase }})</mat-label>
			<textarea matInput formControlName="{{ lang }}"></textarea>
		</mat-form-field>
	</div>

	<div mat-dialog-actions>
		<button type="button" mat-button obButton="tertiary" matTooltip=" {{ 'i18n.button.cancel.tooltip' | translate }}" mat-dialog-close>
			{{ "i18n.button.cancel" | translate }}
		</button>
		<button
			mat-button
			type="submit"
			obButton="primary"
			matTooltip=" {{ 'i18n.button.save.tooltip' | translate }}"
			(click)="save()"
			cdkFocusInitial
			[disabled]="form.pristine || form.invalid"
		>
			{{ "i18n.button.save" | translate }}
		</button>
	</div>
</div>
