<div class="detail-table-row-wrapper">
	<div class="ob-grid padding-top-bottom-xs font-size-smaller">
		<div class="ob-span-2 font-weight-600">{{ label }}</div>
		<div class="ob-span-10">
			<div class="ob-flex">
				<div class="ob-grow-6">
					<ng-content select="[text-content]"></ng-content>
				</div>
				<div class="text-align-right">
					<ng-content select="[button-content]"></ng-content>
				</div>
			</div>
		</div>
	</div>
</div>
