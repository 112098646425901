<div [formGroup]="multiselectForm">
	<mat-form-field>
		<mat-label>{{ translationDropdownFieldName | translate }}</mat-label>
		<mat-select formControlName="selectedOptions" multiple (opened)="setFocus()" (openedChange)="resetOptions()" (selectionChange)="selectOption()">
			<div class="search-wrapper" *ngIf="displaySearch && getOptionsCount() >= minElementsToDisplaySearch">
				<mat-form-field>
					<div class="search-input">
						<input
							type="search"
							name="query"
							matInput
							placeholder="{{ 'i18n.page.catalog.search' | translate }}"
							[(ngModel)]="query"
							[ngModelOptions]="{ standalone: true }"
							(keydown)="search()"
							#searchInput
						/>
						<mat-icon *ngIf="query" svgIcon="cancel" class="ob-icon" (click)="resetOptions()"></mat-icon>
					</div>
				</mat-form-field>
			</div>
			<div class="options-content">
				<mat-option id="all-option" *ngIf="displaySelectAllOption" [value]="selectAllValue">
					{{ "i18n.shared.filters.selectall" | translate }}
				</mat-option>
				<mat-option *ngFor="let option of getSortedOptions()" id="select-option-{{ option.reference }}" [value]="option.reference">
					<span *ngIf="option.label">{{ option.label | fallback: translate.currentLang }}</span>
					<span *ngIf="!option.label">{{ searchFilterService.getI18n(section, option.reference!) | translate }}</span>
					({{ option.count }})
				</mat-option>
			</div>
		</mat-select>
	</mat-form-field>
</div>
