import {Component, Input} from '@angular/core';

@Component({
	selector: 'app-detail-table-row-with-button',
	templateUrl: './detail-table-row-with-button.component.html',
	styleUrls: ['./detail-table-row-with-button.component.scss']
})
export class DetailTableRowWithButtonComponent {
	@Input() label: string = '';
}
